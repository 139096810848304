/**
 * @description 工具类
 * @author chenanhai
 * @date 2020年9月18日15:59:00
 */

// 排序转换
export function sortType(type: string) {
  switch (type) {
    case 'ascending':
      return 'ASC'
    case 'descending':
      return 'DESC'
    default:
      return ''
  }
}

// 排序转换
export function unSortType(type: string) {
  switch (type) {
    case 'ASC':
      return 'ascending'
    case 'DESC':
      return 'descending'
    default:
      return ''
  }
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormatVue.filter
 * @returns {string | null}
 */
export function parseTime(time: string | number | Date | null, cFormat: string): string {
  if (arguments.length === 0) {
    return ''
  }
  if (time === null) {
    return ''
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatObj: any = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const timeStr: string = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    return value.toString().padStart(2, '0')
  })
  return timeStr
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time: number, option: string) {
  if (('' + time).length === 10) {
    time = parseInt(String(time)) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}
