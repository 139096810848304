














import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { IPage } from '@/types'

@Component
export default class Pagination extends Vue {
  @Prop({ default: 10 }) total!: number
  @Prop({ default: 1 }) currentPage!: number

  private page: IPage = { pageIndex: this.currentPage, pageItemCount: this.total }

  private handleSizeChange(pageItemCount: number) {
    this.page.pageItemCount = pageItemCount
    this.sendPage()
  }

  private handleCurrentChange(pageIndex: number) {
    this.page.pageIndex = pageIndex
    this.sendPage()
  }

  @Emit('receivePage')
  private sendPage() {
    return this.page
  }
}
