/**
 * 证书下载
 */
import request from '@/utils/request'
import { IGridParams } from '@/types'

// 获取证书列表
export function getUserGride(data: IGridParams) {
  return request({
    url: '/certificateController/getUserGride',
    method: 'post',
    data: data
  })
}

// 下载文件
export function downloadFile(id: string) {
  return request({
    url: `/system/file/download/${id}`,
    method: 'get',
    responseType: 'blob'
  })
}

// 下载证书
export function dowmCertifid(id: string) {
  return request({
    url: `/certificateFile/dowmCertifid/${id}`,
    method: 'get',
    responseType: 'blob'
  })
}

// 根据上传文件下载证书
export function dowmCertifile(data: object) {
  return request({
    url: `/certificateFile/dowmCertifile`,
    method: 'post',
    data: data,
    responseType: 'blob',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 根据OSS上传文件地址下载证书
export function dowmCertifileByOSS(url: object) {
  return request({
    url: `/certificateFile/dowmCertifileByOSS`,
    method: 'post',
    data: url,
    responseType: 'blob'
  })
}

// 查询记录
export function imitateHisCheckHash(data: object) {
  return request({
    url: `/imitateCertificate/imitateHisCheckHash`,
    params: data,
    method: 'post'
  })
}

// 根据文件历史存证验证
export function imitateUpdate(data: object) {
  return request({
    url: '/imitateCertificate/imitateUpdate',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 根据文本历史存证验证
export function imitateUpdateByString(data: object) {
  return request({
    url: '/imitateCertificate/imitateUpdateByString',
    method: 'post',
    params: data
  })
}

// 上传文件
export function uploadTemplateFile(data: object) {
  return request({
    url: `/certificateFile/uploadFile`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
export function imitatecheckByTxId(data: object) {
  return request({
    url: `/imitateCertificate/imitatecheckByTxId`,
    method: 'post',
    params: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
