













































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { Loading } from 'element-ui';
import { CompleteFile, FileResult, SignInfoParams } from '@/types'
import { uploadWitnessFile, uploadWitnessText } from '@/api/saveEvidence'
import Footer from '@/components/Footer.vue'
import { dowmCertifid, dowmCertifileByOSS,imitatecheckByTxId } from '@/api/credential';
import DigCredentialEdit from './DigCredentialEdit.vue';

@Component({
  components: {
    Footer
  }
})
export default class SaveEvidence extends Vue {
  private owner: string = ''//存证人
  private hashKey: string = ''// hash
  private blockHash: string = ''//区块hash
  private blockNumber: string = ''//区块高度
  private txtime: string = ''//上链时间
  private fileName: string = ''
  private btnLoading: boolean = false
  private btnShow: boolean = false
  // 新增参数
  private previousHash: boolean = ''//上一区块hash
  private realName: boolean = ''//保管人
  private telephone: boolean = ''//联系方式
  private idCard: boolean = ''//身份证
  private organType: boolean = ''//统一社会信用代码
  private fileNames: boolean = ''//文件名称
  private fileSize: boolean = ''//文件大小
  private organization: boolean = ''//保全机构
  private txId: boolean = ''
  private dealids: boolean = false
  private hashs: boolean = '正在保存中'//区块hash
  private hashstall: boolean = '正在保存中'//区块高
  private cc: boolean = '1'//区块高




  




  //  下载
  private cc: any = ''
  private file: any = ''
  private content: any = ''
  private ok(){
    this.$router.go(-1)
  }
  mounted(){
    this.$nextTick(()=>{
      this.$el.querySelector(`#a1`).scrollIntoView({
        behavior: 'smooth',  // 平滑过渡
        block:    'start'  // 上边框与视窗顶部平齐。默认值
      });
    })
    const fileName = window.localStorage.getItem('fileName')
    if (fileName === '区块验证成功') {
      this.btnShow = true
    }
    const fileResult = JSON.parse(window.localStorage.getItem('Czmsg'))
    this.cc = window.localStorage.getItem('cc')
    console.log(fileResult)
    this.owner = fileResult.owner
    this.hashKey = fileResult.hashKey
    this.blockHash = fileResult.blockHash
    this.blockNumber = fileResult.blockNumber
    

      console.log(fileResult)
       var date = new Date(fileResult.txtime * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
       let Y = date.getFullYear() + '-';
       let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
       let D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + ' ';
       let h = (date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
       let m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ':';
       let s = (date.getSeconds() < 10 ? '0'+(date.getSeconds()) : date.getSeconds());
     
       this.txtime = Y+M+D+h+m+s;   
       console.log(this.txtime)
    this.fileName = fileName
    // 新增参数
    this.previousHash = fileResult.previousHash//上一区块hash
    this.realName = fileResult.realName//保管人
    this.telephone = fileResult.telephone//联系方式
    this.idCard = fileResult.idCard//身份证
    this.organType = fileResult.organType//身份证
    this.fileNames = fileResult.fileName//文件名称
    this.fileSize = fileResult.fileSize//文件大小
    this.organization = fileResult.organization//保全机构
    this.txId = fileResult.txId//保全机构
    this.evidenceId = fileResult.evidenceId//编号


  



    //  处理下载
    const params = JSON.parse(window.localStorage.getItem('down'))
    console.log(params)
    this.cc = params.type
    if (this.cc == '1') {
      this.file = params.file
    } else {
      this.content = params.content
    }
  }
  
  // openFullScreen2() {
  //       const loading = this.$loading({
  //         lock: true,
  //         text: 'Loading',
  //         spinner: 'el-icon-loading',
  //         background: 'rgba(0, 0, 0, 0.7)'
  //       });
  //       setTimeout(() => {
  //         loading.close();
  //       }, 2000);
  //     }

  private dealid() {
    this.dealids=true
     const params = {
        'txId': this.txId
      }
      imitatecheckByTxId(params).then((res: any) => {
        console.log(res.data.blockHash,'**********')
        if(res.data.blockHash==''){
          this.hashs='正在保存中'
        }else{
          this.hashs=res.data.blockHash
        }
         if(res.data.blockNumber==''){
          this.hashstall='正在保存中'
        }else{
          this.hashstall=res.data.blockNumber
        }

         }).finally(() => {
        this.btnLoading = false
      })
  }
  //  下载
  private btnDownload() {
    this.btnLoading = true
    if (this.cc == '1') {
      const params = {
        'url': this.file
      }
      dowmCertifileByOSS(params).then((res: any) => {
        console.log(res)
        const content = res.data;
        const blob = new Blob([content], { type: 'application/octet-stream' }); // 构造一个blob对象来处理数据
        // const fileName = this.certificateInfo.evidenceId + '.pdf'
        // const time = this.timestampToTime(this.txtime)
        const time = this.txtime
        console.log(time)
        const fileName = 'JBC' + time + '.pdf'
        const link = document.createElement('a'); // 创建a标签
        link.download = fileName;
        link.style.display = 'none';
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click(); // 执行下载
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }).finally(() => {
        this.btnLoading = false
      })
    } else {
      const id = this.content
      dowmCertifid(id).then((res: any) => {
        const content = res.data;
        const blob = new Blob([content], { type: 'application/octet-stream' }); // 构造一个blob对象来处理数据
        // const fileName = this.certificateInfo.evidenceId + '.pdf'
        //const time = this.timestampToTime(this.txTime)
        const time = this.txtime
        console.log(time)
        const fileName = 'JBC' + time + '.pdf'
        const link = document.createElement('a'); // 创建a标签
        link.download = fileName;
        link.style.display = 'none';
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click(); // 执行下载
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }).finally(() => {
        this.btnLoading = false
      })
    }
  }

  private timestampToTime(row: any) {
    const date = new Date(row)
    return date.getTime()
  }

  private dataURLtoFile(urlData: any, fileName: any) {
    const arr = urlData.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bytes = atob(arr[1]); // 解码base64
    let n = bytes.length
    const ia = new Uint8Array(n);
    while (n--) {
      ia[n] = bytes.charCodeAt(n);
    }
    return new File([ia], fileName, { type: mime });
  }
}
