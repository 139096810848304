
















import { Component, Vue } from 'vue-property-decorator'
import Nav from '@/components/Nav.vue'
import BackNav from '@/components/BackNav.vue'
import Footer from '@/components/Footer.vue'

@Component({
  components: {
    Nav,
    BackNav,
    Footer
  }
})
/**
 * @description 首页
 * @author chenanhai
 * @date 2020年8月20日09:52:08
 */
export default class Home extends Vue {
  private isShowNav: boolean = true
  private redirect: string = '/'

  receive(navFlag: boolean) {
    this.isShowNav = navFlag
  }
}
