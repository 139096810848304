





























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { ITracing } from '@/types'

@Component
export default class DigCredentialInfo extends Vue {
  @Prop({ default: false }) visible!: boolean
  @Prop({ default: false }) tracingDate!: Array<ITracing>

  private title: string = '查看'

  @Emit('receiveVisible')
  private onClose() {
    return false
  }

  get dlgVisible(): boolean {
    return this.visible
  }
}
