/**
 * 单位接口
 */
import request from '@/utils/request'
import { ICompanyInfo, IGridParams } from '@/types'

// 获取单位列表
export function getOrgTree(data: IGridParams) {
  return request({
    url: '/system/orgView/getOrgTree',
    method: 'post',
    data: data
  })
}
// 保存
export function addOrEditOrg(data: ICompanyInfo) {
  return request({
    url: '/system/org/addOrEditOrg',
    method: 'post',
    data: data
  })
}
// 删除
export function deleteOrg(Id: string) {
  return request({
    url: `system/org/deleteOrg/${Id}`,
    method: 'delete'
  })
}
