import { render, staticRenderFns } from "./kefu.vue?vue&type=template&id=d299d54e&scoped=true&"
import script from "./kefu.vue?vue&type=script&lang=ts&"
export * from "./kefu.vue?vue&type=script&lang=ts&"
import style0 from "./kefu.vue?vue&type=style&index=0&id=d299d54e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d299d54e",
  null
  
)

export default component.exports