



























import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

interface LogKey {
  key: string;
  after?: string | number | boolean;
  before: string | number | boolean;
}

@Component
export default class DlgLogDetail extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop({ default: '' }) type!: string;
  @Prop({ default: [] }) detailList!: any;

  private logKeyList: Array<LogKey>= [];

  private onSubmit() {
  }

  @Emit('update:visible')
  private onClose() {
    this.logKeyList = [];
    return false
  }

  get dlgVisible(): boolean {
    return this.visible;
  }

  @Watch('visible')
  authStatus() {
    if (this.visible && this.detailList.length > 0) {
      const beforeDetail = this.detailList[0];
      if (this.type === '更新' && this.detailList.length > 1) {
        const afterDetail = this.detailList[1];
        for (const i in beforeDetail) {
          const logDetail = { key: i, before: beforeDetail[i], after: afterDetail[i] };
          this.logKeyList.push(logDetail);
        }
      } else {
        for (const i in beforeDetail) {
          const logDetail = { key: i, before: beforeDetail[i] };
          this.logKeyList.push(logDetail);
        }
      }
    }
  }
}
