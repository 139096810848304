/**
 * 日志接口
 */
import request from '@/utils/request'
import { IGridParams } from '@/types'

export function getGrid(data: IGridParams) {
  return request({
    url: '/system/log/getGrid',
    method: 'post',
    data: data
  })
}
