























import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { Result, ResourceRole, ResultList, ResultM } from '@/types'
import { getResourceRoleTree, getRoleResource } from '@/api/resource'
import { empowerResources } from '@/api/role'

@Component
export default class DlgRoleResource extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop({ default: '' }) roleId!: string;

  private treeData: [] = [];
  private checkData: Array<any> = [];

  private defaultProps = {
    children: 'children',
    label: 'title'
  };

  filterNode(value: string, data: {title: ''}) {
    if (!value) return true;
    return data.title.indexOf(value) !== -1;
  }

  private onSubmit() {
    const ref: any = this.$refs.tree
    empowerResources(this.roleId, ref.getCheckedKeys()).then((data: ResultM) => {
      let msg = ''
      if (data.message != undefined) {
        msg = data.message
      }
      this.$message.success(msg);
      this.refresh();
      this.onClose();
    })
  }

  @Emit('update:visible')
  private onClose() {
    this.treeData = [];
    this.checkData = [];
    return false
  }

  get dlgVisible(): boolean {
    return this.visible;
  }

  @Watch('visible')
  authStatus() {
    if (this.visible) {
      this.getResourceRoleTree()
      this.getRoleResource()
    }
  }

  private getResourceRoleTree() {
    getResourceRoleTree().then((data: Result<any>) => {
      if (data.code) {
        this.treeData = data.data
      }
    })
  }

  private getRoleResource() {
    getRoleResource(this.roleId).then((data: ResultList<ResourceRole>) => {
      if (data.code) {
        for (const i in data.data) {
          this.checkData.push(data.data[i].sId)
        }
      }
    })
  }

  @Emit('refresh')
  private refresh() {}
}
