














import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { ResultList, ResultM, Distribute, Transfer } from '@/types'
import { initRoleUsers, getRoleSelectedUsers, saveRoleSelectedUsers } from '@/api/role'

@Component
export default class DlgRoleDistribute extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop({ default: '' }) roleId!: string;

  private treeData: Array<Transfer> = [];
  private checkData: Array<any> = [];

  private defaultProps = {
    children: 'children',
    label: 'title'
  };

  private onSubmit() {
    saveRoleSelectedUsers(this.roleId, this.checkData).then((data: ResultM) => {
      let msg = ''
      if (data.message != undefined) {
        msg = data.message
      }
      this.$message.success(msg);
      this.refresh();
      this.onClose();
    })
  }

  @Emit('update:visible')
  private onClose() {
    this.treeData = [];
    this.checkData = [];
    return false
  }

  get dlgVisible(): boolean {
    return this.visible;
  }

  @Watch('visible')
  authStatus() {
    if (this.visible) {
      this.initRoleUsers()
      this.getRoleSelectedUsers()
    }
  }

  private initRoleUsers() {
    initRoleUsers().then((data: ResultList<Distribute>) => {
      if (data.code) {
        for (const i in data.data) {
          const params = {
            key: data.data[i].id,
            label: data.data[i].text
          }
          this.treeData.push(params)
        }
      }
    })
  }

  private getRoleSelectedUsers() {
    getRoleSelectedUsers(this.roleId).then((data: ResultList<Distribute>) => {
      if (data.code) {
        for (const i in data.data) {
          this.checkData.push(data.data[i].id)
        }
      }
    })
  }

  @Emit('refresh')
  private refresh() {}
}
