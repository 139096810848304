/**
 * @description 用户接口
 * @author chenanhai
 * @date 2020年8月24日10:13:13
 */
import { VuexModule, Module, getModule, Action, Mutation } from 'vuex-module-decorators'
import store from '@/store'
import { getUserInfo, login } from '@/api/user'
import { IUser } from '@/types'
import { MenuModule } from '@/store/modules/menu'
import { setToken, removeToken, getToken, getName, setName, removeName } from '@/utils/session'
import router from '@/router';

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUser {
  public token: string = '';
  public name = '';
  public avatar = '';
  public introduction = '';
  public roles: string[] = [];
  public email = '';
  public id = '';
  
  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token
  }

  @Mutation
  private SET_ID(id: string) {
    this.id = id
  }

  @Mutation
  private SET_NAME(name: string) {
    this.name = name
  }

  @Action({ rawError: true })
  public async Login(userInfo: { username: string; password: string}): Promise<boolean> {
    const { username, password } = userInfo
    const result = await login({ username, password });
    if (result) {
      const data = result.data;
      setToken(data.access_token);
      this.SET_TOKEN(data.access_token);
      this.SET_NAME(data.username);
      this.SET_ID(data.id);
      setName(data.username);
      return true
    }
    return false
  }

  @Action({ rawError: true })
  public async getUserInfo() {
    if (getToken()) {
      this.SET_TOKEN(getToken());
      const { data } = await getUserInfo();
      // this.SET_MENU(menus.data)
      if (data) {
        this.SET_NAME(data.uRealName);
        this.SET_ID(data.id);
      } else {
        this.SET_ID('1');
        this.SET_NAME('超级管理员' + getName());
        await MenuModule.getMenus();
        const accessRoutes: any = await MenuModule.generateRoutes(store.getters.menus);
        router.addRoutes(accessRoutes)
      }
      console.log('user', data, this.name);
      return true
    } else {
      return false
    }
  }

  @Action({ rawError: true })
  public async logout() {
    this.SET_TOKEN('');
    this.SET_NAME('');
    removeToken();
    removeName();
  }

  
}

export const UserModule = getModule(User);
