


































import { Component, Vue } from 'vue-property-decorator'
import { LoginParams } from '@/types'
import { UserModule } from '@/store/modules/user'
import { copyright } from '@/utils/dict'

/**
 * @description 登录
 * @author chenanhai
 * @date 2020年8月24日15:40:20
 */
@Component
export default class Login extends Vue {
  private form: LoginParams = { username: '', password: '' }
  private copyright = copyright
  private redirect = '/'
  private loading: boolean = false
  // 登录
  private async handleLogin() {
   
    this.loading = true
    try {
      const result = await UserModule.Login(this.form);
      if (result) {
        
        await UserModule.getUserInfo();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const query: any = this.$route.query
        if (query) {
          this.redirect = query.redirect
        }
        await this.$router.push({ path: this.redirect || '/' })
      }
      this.loading = false
    } catch (e) {
      this.loading = false
    }
  }
}
