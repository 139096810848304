/**
 * @description session 信息存储
 * @author chenanhai
 * @date 2020年8月26日10:42:26
 */
export function getToken(): string {
  let token = ''
  const accessToken = sessionStorage.getItem('vue_typescript_admin_access_token')
  if (accessToken) {
    token = accessToken
  }
  return token
}

export function getName(): string {
  let name = ''
  const accessToken = sessionStorage.getItem('vue_typescript_admin_access_name')
  if (accessToken) {
    name = accessToken
  }
  return name
}

export function setToken(token: string) {
  sessionStorage.setItem('vue_typescript_admin_access_token', token)
}

export function setName(name: string) {
  sessionStorage.setItem('vue_typescript_admin_access_name', name)
}

export function removeToken() {
  sessionStorage.removeItem('vue_typescript_admin_access_token')
}

export function removeName() {
  sessionStorage.removeItem('vue_typescript_admin_access_name')
}

export function clearSession() {
  sessionStorage.clear()
}

export function getSessionByParam(param: string): string {
  let result = ''
  const sessionResult = sessionStorage.getItem(param)
  if (sessionResult) {
    result = sessionResult
  }
  return result
}

export function setSession(param: string, name: string) {
  sessionStorage.setItem(param, name)
}

export function removeSessionByParam(param: string) {
  sessionStorage.removeItem(param)
}
