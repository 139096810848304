





















































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { IGridParams, ICondictions, IPage, IUserInfo, ResultM, Result, MType } from '@/types'
import { getUserList, setLock, logicDeleteUser, excelExportData, getImpoweredDataById, resetPassword } from '@/api/user'
import DlgUserInfoEdit from '@/views/system/DlgUserInfoEdit.vue'
import DlgUserDataAuth from '@/views/system/DlgUserDataAuth.vue'
import { dictSex } from '@/utils/dict'
import { sortType, unSortType } from '@/utils/util'
import Pagination from '@/components/Pagination.vue'
import { getWhereConditions, userWhereConditions } from '@/types/where-condition'
import { MessageBox } from 'element-ui'
import DlgUserCompany from '@/views/system/DlgUserCompany.vue'
import TreeSelect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { getOrganizationTree } from '@/api/organization'

@Component({
  components: {
    DlgUserInfoEdit,
    Pagination,
    DlgUserDataAuth,
    DlgUserCompany,
    TreeSelect
  },
  filters: {
    sexFilter(status: string) {
      return dictSex(status)
    }
  }
})
export default class UserList extends Vue {
  private listLoading: boolean = false;
  private isEditVisible: boolean = false; // 编辑
  public isDataAuthVisible: boolean = false; // 数据权限
  public isCompanyVisible: boolean = false; // 所属单位
  public userIds: string[] = []; // 选中用户id
  public userInfo: IUserInfo = {}; // 选中用户id
  public btnDisabled: boolean = true; // 按钮选中
  public currMType: number = 0; // 权限类型

  private tableData: Array<IUserInfo> = [];
  private form: IGridParams = {
    pageIndex: 1,
    pageItemCount: 10,
    whereConditions: [],
    orderConditions: [{ field: 'createTime', method: 'DESC' }]
  };

  private tree = {
    treeOrg: [],
    value: null
  };

  private normalizer(node: { key: string; title: string; children: [] }) {
    if (!node.children || node.children.length === 0) {
      delete node.children;
    }
    return {
      id: node.key,
      //将name转换成必填的label键
      label: node.title,
      children: node.children
    }
  }

  private whereCondition = {
    uNameLike: '',
    uRealNameLike: '',
    createTimeGreaterThanEqual: '',
    createTimeLessThanEqual: '',
    uLastOnLineGreaterThanEqual: '',
    uLastOnLineLessThanEqual: '',
    zhEqual: '',
    oIdEqual: ''
  };

  private sortOrders: string[] = [];
  private totalCount: number = 0;

  // 时间范围
  private createTimeArr: string[] = [];
  private uLastOnLineArr: string[] = [];

  private see: boolean = false;

  created() {
    // 获取用户数据
    this.getList();
    this.getOrganizationTree();
  }

  private handleEdit(row: IUserInfo) {
    this.userInfo = row;
    this.isEditVisible = true;
  }

  private handleReset(userId: string) {
    if (userId) {
      this.$confirm('确定重置该用户的密码？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        resetPassword(userId).then((data: ResultM) => {
          if (data.code == 200) {
            this.$message.success('密码重置为123456');
            return
          }
          this.$message.error('密码重置失败');
        });
      }).catch(() => {
        console.log('取消')
      })
    } else {
      this.$message.warning('刷新重试')
    }
  }

  private handleAdd() {
    this.userInfo = {}
    this.isEditVisible = true;
  }

  receiveVisible(vis: boolean) {
    this.isEditVisible = vis;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleSort(object: any) {
    const orderType = sortType(object.order);
    const order: ICondictions = { field: object.prop, method: orderType };
    const field = object.prop;
    this.form.orderConditions = this.form.orderConditions?.filter(item => item.field != field);
    if (object.order) {
      this.form.orderConditions?.push(order);
    }
    this.getList();
    this.sortOrders = [];
    this.form.orderConditions?.forEach(item => {
      this.sortOrders.push(unSortType(item.method));
    })
  }

  private handleChangeLock(userId: string) {
    setLock(userId).then((data: ResultM) => {
      if (data.code == 200) {
        this.$message.success('状态修改成功');
        return
      }
      this.$message.error('状态修改失败');
    })
  }

  private handleChangeDelete(userId: string) {
    logicDeleteUser(userId).then((data: ResultM) => {
      if (data.code == 200) {
        this.$message.success('状态修改成功');
        return
      }
      this.$message.error('状态修改失败');
    })
  }

  private handleSearch() {
    if (this.createTimeArr && this.createTimeArr.length > 0) {
      this.whereCondition.createTimeGreaterThanEqual = this.createTimeArr[0];
      this.whereCondition.createTimeLessThanEqual = this.createTimeArr[1].replace('00:00:00', '23:59:59');
    }
    if (this.createTimeArr && this.uLastOnLineArr.length > 0) {
      this.whereCondition.uLastOnLineGreaterThanEqual = this.uLastOnLineArr[0];
      this.whereCondition.uLastOnLineLessThanEqual = this.uLastOnLineArr[1].replace('00:00:00', '23:59:59');
    }
    this.getList()
  }

  private getList() {
    this.whereCondition.oIdEqual = this.tree.value || '';
    this.listLoading = true;
    const where = getWhereConditions(this.whereCondition, userWhereConditions);
    this.form.whereConditions = where;
    getUserList(this.form).then((data: any) => {
      if (data.data) {
        this.tableData = data.data.data;
        this.totalCount = data.data.totalCount;
        this.form.pageItemCount = data.data.pageItemCount;
        this.form.pageIndex = data.data.pageIndex;
      }
    }).finally(() => {
      this.listLoading = false;
    })
  }

  private receivePage (page: IPage) {
    this.form.pageItemCount = page.pageItemCount ? page.pageItemCount : 10;
    this.form.pageIndex = page.pageIndex ? page.pageIndex : 1;
    this.getList();
  }

  private exportExt (type: number) {
    if (type === 1) {
      this.confirmExt('您确定要导出所有数据？ 因数据较多可能耗时较长! 请耐心等待...', type);
    } else if (type === 2) {
      this.confirmExt('您确定要导出选定的' + this.totalCount + '条数据?', type);
    } else if (type === 3) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const obj: any = this.$refs.multipleTable;
      if (obj.selection.length > 0) {
        this.confirmExt('您确定要导出选定的' + obj.selection.length + '条数据?', type);
      } else {
        this.$message.warning('您还没有勾选任何数据，请重新选择将要导出的数据')
      }
    }
  }

  private confirmExt(msg: string, type: number) {
    MessageBox.confirm(msg, '导出确认', {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      const obj = {
        whereConditions: this.form.whereConditions,
        orderConditions: this.form.orderConditions
      };
      if (type === 3) {
        const ids: string[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const selArr: any = this.$refs.multipleTable;
        selArr.selection.forEach((item: { id: string | number | undefined }) => {
          ids.push('\'' + item.id + '\'')
        });
        const conDict: ICondictions = { field: '', method: '' };
        conDict.field = 'id';
        conDict.method = 'In';
        conDict.value = ids.join(',');
        obj.whereConditions?.push(conDict);
      }
      excelExportData(obj).then((response: any) => {
        const content = response.data;
        const blob = new Blob([content], { type: 'application/vnd.ms-excel' }); // 构造一个blob对象来处理数据
        // const fileName = response.headers.filename // 导出文件名
        const link = document.createElement('a'); // 创建a标签
        link.download = '用户信息表';
        link.style.display = 'none';
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click(); // 执行下载
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      })
    }).catch(e => {
      if (e !== 'cancel') {
        this.$message.error('error')
      }
    })
  }

  public refresh () {
    this.getList();
  }

  // 所属单位
  private handleCompany () {
    this.isCompanyVisible = true;
  }

  // 数据权限
  private handleDataAuth () {
    if (this.userIds.length > 0) {
      let oidFlag: boolean = false
      const selArr: any = this.$refs.multipleTable;
      selArr.selection.forEach((item: { oId: string | null | undefined }) => {
        if (!item.oId) {
          oidFlag = true
        }
      });
      if (oidFlag) {
        this.$message.error('所选部分用户没有所属单位，不能进行数据授权！请重新选择用户！');
        return
      }

      const userId = this.$store.getters.userId;
      getImpoweredDataById(userId).then((data: Result<MType>) => {
        if (data.code === 200) {
          this.currMType = data.data.mType
        } else {
          this.$message.error('没有数据权限！');
        }
      });
      this.isDataAuthVisible = true;
    } else {
      this.$message.warning('请先选中记录')
    }
  }

  private reset () {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = this.whereCondition;
    for (const i in obj) {
      obj[i] = '';
    }
  }

  private handleShow() {
    this.see = !this.see;
  }

  // 选中控制 btn
  private handleSelectionChange() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selArr: any = this.$refs.multipleTable;
    this.userIds = [];
    selArr.selection.forEach((item: { id: string | number | undefined }) => {
      this.userIds.push(String(item.id))
    });

    if (this.userIds.length > 0) {
      this.btnDisabled = false
    } else {
      this.btnDisabled = true
    }
  }

  private getOrganizationTree() {
    getOrganizationTree().then((data: Result<any>) => {
      if (data.code) {
        this.tree.treeOrg = data.data;
      }
    })
  }
}
