
























import { Vue, Component } from 'vue-property-decorator'
import { FileResult, HashCheckForm } from '@/types'
import { sendSms, hashVerify } from '@/api/fileCheck'
import { ElForm } from 'element-ui/types/form'
import DigCheckInfo from '@/views/info/DigCheckInfo.vue'
import Footer from '@/components/Footer.vue'

@Component({
  components: {
    DigCheckInfo,
    Footer
  }
})
export default class HashCheck extends Vue {
  private btnStatus = false
  private smsStatus = true
  private smsHint: string = '发送短信'
  private smsId: string = ''
  private btnLoading: boolean = false
  private isEditVisible: boolean = false
  private form: HashCheckForm = {
    hashCode: '',
    code: ''
  }

  private fileResult: FileResult = {
    id: '',
    hashKey: '',
    hashType: '',
    blockHash: '',
    blockNumber: '',
    orgcode: '',
    remark: '',
    saveType: '',
    txId: '',
    txTime: '',
    owner: '',
    createtime: ''
  }

  //  表单校验
  private rules = {
    hashCode: [
      { required: true, message: '文件hash不能为空', trigger: 'blur' }
    ]
  }

  //  hash验证
  private hashCheck(formName: string): void {
    (this.$refs[formName] as ElForm).validate((valid: boolean) => {
      if (valid) {
        this.btnLoading = true
        hashVerify(this.form.hashCode).then(res => {
          console.log(res)
          if (res != undefined && res.data != null) {
            this.fileResult = res.data
            this.$message.success(res.message)
            this.isEditVisible = true
          }
          this.btnLoading = false
        }).finally(() => {
          this.btnLoading = false
        })
      }
    })
  }

  //  短信验证
  private smsCode(): void {
    //  输入框状态
    this.smsStatus = false
    //  按钮状态
    this.btnStatus = true
    //  发送短信
    sendSms().then(res => {
      console.log(res.data)
      //  获取验证码返回值
      this.smsId = res.data
    })
    //  倒计时重新发送
    let time: number = 60
    const interval = window.setInterval(() => {
      this.smsHint = '（' + time + '秒）后重新发送'
      time--
      if (time < 0) {
        this.smsHint = '重新发送'
        time = 60
        this.btnStatus = false
        window.clearInterval(interval)
      }
    }, 1000)
  }

  receiveVisible(vis: boolean) {
    this.isEditVisible = vis
  }
}
