/**
 * 链码溯源接口
 */
import request from '@/utils/request'

export function hisVerification(data: object) {
  return request({
    // url: '/witnesses-verification',
    url: '/imitateCertificate/imitateHisVerification',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function hisVerificationByString(data: object) {
  return request({
    // url: '/witnesses-verification',
    url: `/imitateCertificate/imitateHisVerificationByString`,
    params: data,
    method: 'post'
  })
}
