import { ICondictions } from '@/types/index'

export const userWhereConditions: Array<ICondictions> = [
  { field: 'uName', method: 'Like', value: '' },
  { field: 'uRealName', method: 'Like', value: '' },
  { field: 'createTime', method: 'GreaterThanEqual', value: '' },
  { field: 'createTime', method: 'LessThanEqual', value: '' },
  { field: 'uLastOnLine', method: 'GreaterThanEqual', value: '' },
  { field: 'uLastOnLine', method: 'LessThanEqual', value: '' },
  { field: 'oId', method: 'Equal', value: '' },
  { field: 'zh', method: 'Equal', value: 0 }];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getWhereConditions (obj: any, whereConditions: Array<ICondictions>): Array<ICondictions> {
  const arr: Array<ICondictions> = [];
  whereConditions.forEach(item => {
    for (const i in obj) {
      if (i === item.field + item.method && obj[i]) {
        const conDict: ICondictions = { field: '', method: '' };
        conDict.field = item.field;
        conDict.method = item.method;
        conDict.value = obj[i];
        arr.push(conDict);
      }
    }
  });
  return arr
}

export const logWhereConditions: Array<ICondictions> = [
  { field: 'createUserName', method: 'Like', value: '' },
  { field: 'ip', method: 'Like', value: '' },
  { field: 'createTime', method: 'GreaterThanEqual', value: '' },
  { field: 'createTime', method: 'LessThanEqual', value: '' }];

export const credentialWhereConditions: Array<ICondictions> = [
  { field: 'evidenceId', method: 'Equal', value: '' },
  { field: 'evidenceName', method: 'Like', value: '' }];
