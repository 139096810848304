import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
// import store from '@/store'
import { getToken } from '@/utils/session'
import { getCode } from '@/utils/dict'
import { UserModule } from '@/store/modules/user'
// import UUID from 'es6-uuid'

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: 'https://cz.zgsfff.com:8080/', //阿里云后台服务器
  // baseURL: 'http://djm665.natappfree.cc/', //内网穿透服务器
  // baseURL: 'http://localhost:8080/', //本机测试服务器
  // maxContentLength: 99999,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config: any) => {
    if (getToken()) {
      // config.headers['X-Token'] = getToken()
      config.headers.Authorization = 'Bearer ' + getToken()
      config.headers.timestamp = new Date().getTime()
      config.headers.nonce = new Date().getTime()
    }
    return config
  },
  (error: any) => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response: any) => {
    if (
      response.headers['content-type'] ===
        'application/octet-stream;charset=UTF-8' ||
      response.headers['content-type'] === 'text/csv;charset=UTF-8' ||
      response.headers['content-type'] ===
        'application/vnd.ms-excel;charset=utf-8'
    ) {
      return response
    }
    const res = response.data
    if (res.code !== 200) {
      if (res.message.replace(/\s+/g, '').indexOf('notexist') != -1) {
        Message({
          message: '目标区块不存在',
          type: 'error',
          duration: 3 * 1000
        })
      } else if (
        res.message.replace(/\s+/g, '').indexOf('alreadyexist') != -1
      ) {
        Message({
          message: '目标区块已存在',
          type: 'error',
          duration: 3 * 1000
        })
      } else {
        Message({
          message: getCode(res.code, res.message) || 'Error',
          type: 'error',
          duration: 3 * 1000
        })
      }

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (
        res.code === 50008 ||
        res.code === 50012 ||
        res.code === 50014 ||
        res.code === 401
      ) {
        // console.log('  const path = this.$route.path', this.$route.path)
        // to re-login
        MessageBox.confirm('登录超时，您确认重新登录么？', '退出登录', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await UserModule.logout()
          location.reload()
        })
      }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return Promise.reject(new Error(JSON.stringify(res) || 'Error'))
    } else {
      return res
    }
  },
  (error: any) => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
