


















































import { Vue, Component, } from 'vue-property-decorator'

import Footer from '@/components/Footer.vue'
import elTableInfiniteScroll from 'el-table-infinite-scroll';
@Component({
  components: {
    Footer
  },
  directives: {
    'el-table-infinite-scroll': elTableInfiniteScroll
  }
})
export default class Tracing extends Vue {
  private tracing: boolean = []
  private cincer: boolean = ''
  private name: boolean = ''
  private ok(){
    this.$router.go(-1)
  }







  mounted() {
   
  }
  created() {
      console.log(1111111111111)
      this.tracing = JSON.parse(window.sessionStorage.getItem('tracing'))
       this.tracing.data.reverse();
      this.cincer = window.sessionStorage.getItem('cincer')
      if(this.cincer=='0'){
          this.name = this.tracing.data[0].fileName
      }else{
         this.name=window.sessionStorage.getItem('textarea')
      }
      console.log(this.tracing.data.length,'9999')
      console.log(this.tracing)
      console.log(this.cincer)


  }
}
