


























import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { IMenuInfo, ResultM } from '@/types'
import { saveMenu } from '@/api/menu'
import { ElForm } from 'element-ui/types/form';

@Component
export default class DlgMenuInfoEdit extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop({ default: {} }) menuInfo!: IMenuInfo;
  private form: IMenuInfo = {
    id: null,
    sHide: '1',
    sIcon: null,
    sIndex: '',
    sKey: '',
    sKind: 'web',
    sName: '',
    sParentId: null,
    sType: 0
  };

  private rules = {
    sName: [
      { required: true, message: '请输入菜单名称', trigger: 'blur' }
    ],
    sKey: [
      { required: true, message: '请输入菜单key', trigger: 'blur' }
    ],
    sIndex: [
      { required: true, message: '请输入菜单排序', trigger: 'blur' }
    ]
  };

  private title: string = '';

  private onSubmit() {
    this.updateUser('postForm');
  }

  private updateUser(formName: string) {
    (this.$refs[formName] as ElForm).validate((valid: boolean) => {
      if (valid) {
        saveMenu(this.form).then((data: ResultM) => {
          let msg = ''
          if (data.message != undefined) {
            msg = data.message
          }
          if (data.code === 200) {
            this.$message.success(msg);
            this.refresh();
            this.onClose();
          } else {
            this.$message.error(msg);
          }
        });
      }
    })
  }

  @Emit('refresh')
  private refresh() {}

  @Emit('update:visible')
  private onClose() {
    return false
  }

  @Watch('visible')
  authStatus() {
    // 清除验证
    const formName = 'postForm'
    this.$nextTick(() => {
      (this.$refs[formName] as ElForm).clearValidate();
    })
    if (this.visible) {
      if (this.menuInfo.id) {
        this.title = '编辑菜单';
        this.form = this.menuInfo;
      } else {
        this.title = '新增菜单';
        this.form = {
          id: null,
          sHide: '1',
          sIcon: null,
          sIndex: '',
          sKey: '',
          sKind: 'web',
          sName: '',
          sParentId: null,
          sType: 0
        }
      }
      console.log(this.form)
    }
  }

  get dlgVisible(): boolean {
    return this.visible
  }
}
