
























import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { FileResult } from '@/types'

@Component
export default class DlgCheckInfo extends Vue {
  @Prop({ default: false }) visible!: boolean
  @Prop({ default: false }) fileResult!: FileResult

  private title: string = ''

  private onSubmit() {
    this.visible = false
    return false
  }

  @Emit('receiveVisible')
  private onClose() {
    return false
  }

  get dlgVisible(): boolean {
    return this.visible
  }
}
