


























































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { CompleteFile, FileResult, SignInfoParams } from '@/types'
import { uploadWitnessFile, uploadWitnessText } from '@/api/saveEvidence'
import Footer from '@/components/Footer.vue'
import kefu from '@/components/kefu.vue'
@Component({
  components: {
    Footer,
    kefu
  }
})
export default class SaveEvidence extends Vue {
  private isShowTop: boolean = false
  private topBtn(){
    this.$nextTick(()=>{
      this.$el.querySelector(`#a1`).scrollIntoView({
        behavior: 'smooth',  // 平滑过渡
        block:    'start'  // 上边框与视窗顶部平齐。默认值
      });
    })
  }
  private handleScroll(){
    window.addEventListener('scroll',(e)=>{
      if(e.target.scrollTop >= 0 && e.target.scrollTop < 580){
        this.isShowTop = false
      }
      if(e.target.scrollTop > 580 && e.target.scrollTop < 1180){
        this.isShowTop = true
      }

    },true)
  }
  mounted(){
    this.topBtn()
    this.handleScroll();
  }
}
