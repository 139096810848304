




























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { UserModule } from '@/store/modules/user'
import { getSessionByParam, getToken, setSession } from '@/utils/session'
/**
 * @description 导航菜单
 * @author chenanhai
 * @date 2020年8月20日09:52:08
 */
@Component
export default class Nav extends Vue {
  private activeIndex: string = '/';
  private activeIndexNum: string = '';
  // private managerType: string = '';
  private backPath: string = 'tfkjcah12345ccaba';
  private errorPath: string = 'error';
  private loginFlag: boolean = true;
  private managerType: number = 1;
  private systemId: string = '848860bd-4aef-4d82-9c9a-4b19864c3c98'; // 系统管理
  private menuListFront: Array<object> = [
    { key: 'a1', title: '首页', path: '/', index: '0' },
    { key: 'a2', title: '法连介绍', path: '/', index: '1' },
    { key: 'a3', title: '应用场景', path: '/', index: '2' },
    { key: 'a4', title: '接入管理', path: '/', index: '3' },
    { id: 'a5', title: '法连在线', path: '/about2', children: [
      { id: 'a5.1', title: '区块存证', path: '/save-evidence' }, 
      { id: 'a5.2', title: '区块验证', path: '/file-check' }, 
      // { id: 'a5.3', title: '历史证书', path: '/credential' }, 
      { id: 'a6.4', title: '链码溯源', path: '/tracing' }] }
    // { key: 'a2', title: '区块存证', path: '/save-evidence', index: '4' },
    // { key: 'a3', title: '区块验证', path: '/file-check', index: '5' },
    // { key: 'a5', title: '历史证书', path: '/credential', index: '6' },
    // { key: 'a7', title: '链码溯源', path: '/tracing', index: '7' }
  ];

  private menuList: Array<object> = this.menuListFront;

  // private menuListBack: Array<object> = [
  //   { key: 'b1', title: '用户管理', path: '/tfkjcah12345ccaba/user' },
  //   { key: 'b2', title: '角色管理', path: '/tfkjcah12345ccaba/role' },
  //   { key: 'b3', title: '菜单管理', path: '/tfkjcah12345ccaba/menu' },
  //   { key: 'b4', title: '日志管理', path: '/tfkjcah12345ccaba/log' }
  // ]
  private fixNav: string = '1';
  private isFalg: string = 'true'

  @Prop()
  private msg!: string;

  created () {
    this.loginFlag = !getToken();
  }

  mounted (){
    
    window.addEventListener('setIsScoll', ()=> {
      console.log(sessionStorage.getItem('isScoll'))
      this.isFalg = sessionStorage.getItem('isScoll')
    })


    window.addEventListener('setNavName', ()=> {
      console.log(sessionStorage.getItem('navName'))
      if(this.isFalg == 'false'){
        return false;
      }
      if(this.activeIndexNum == ''){
        this.activeIndexNum = '1'
      }
      if(this.activeIndexNum == '1' || this.activeIndexNum == '2' || this.activeIndexNum == '3' || this.activeIndexNum == '4'){
        this.fixNav = sessionStorage.getItem('navName')
      }
    })
  }

  get menuListBack () {
    const sysMenu = this.$store.getters.menus || [];
    return sysMenu
  }

  get username(): string {
    return this.$store.getters.username
  }

  get userId(): string {
    return this.$store.getters.userId
  }

  private handleSelect(val: string, oldVal: string) {
    this.activeIndexNum = val
    switch (val) {
      case '1' :
        this.resetSetItem('scrollName','a1');
        this.isScoll('isScoll','true');
        break;
      case '2' :
        this.resetSetItem('scrollName','a2');
        this.isScoll('isScoll','true');
        break;
      case '3' :
        this.resetSetItem('scrollName','a3');
        this.isScoll('isScoll','true');
        break;
      case '4' :
        this.resetSetItem('scrollName','a4');
        this.isScoll('isScoll','true');
        break;
      default:
        console.log(val)
        break;
    }
    // console.log('this.$store ', this.$store.getters.username)
    // getUserInfo().then(data => {
    //   console.log('getUserInfo  data', data)
    // })
  }

  private handleBack(type: number) {
    if (getToken()) {
      this.managerType = type;
      // console.log(type, this.$route.fullPath.indexOf(this.backPath), this.$route.fullPath, this.backPath)
      setSession('managerType', this.managerType + '');
      if (type === 2) {
        this.menuList = this.menuListBack;
        if (this.$route.fullPath.indexOf(this.backPath) === -1 &&
          this.$route.fullPath.indexOf(this.errorPath) === -1) {
          this.$router.push({ name: 'UserList' });
        }
      } else {
        this.menuList = this.menuListFront;
        if (this.$route.fullPath.indexOf(this.backPath) !== -1) {
          this.$router.push({ path: '/' });
        }
      }
    } else {
      this.managerType = 1;
      this.menuList = this.menuListFront;
      if (this.$route.fullPath.indexOf(this.backPath) !== -1) {
        this.$router.push({ path: '/' });
      }
    }
  }

  private logout() {
    UserModule.logout();
    this.managerType = 1;
    setSession('managerType', this.managerType + '');
    this.loginFlag = true;
    this.menuList = this.menuListFront;
    this.$router.push(`/`);
  }

  private activeMenu(): string {
    const route = this.$route;
    const { meta, path } = route;
    // if set path, the sidebar will highlight the path you set
    if (meta.activeMenu) {
      return meta.activeMenu
    }
    return path
  }

  @Watch('$route')
  routeChange(val: Route): void {
    this.activeIndex = val.path;
    if (getToken()) {
      this.loginFlag = false;
      this.handleBack(+getSessionByParam('managerType'));
    } else {
      this.loginFlag = true;
    }
  }

  

}
