


































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { SignInfoParams, CompleteFile, ITracing } from '@/types'
import { hisVerification, hisVerificationByString } from '@/api/tracing'
import Footer from '@/components/Footer.vue'
import elTableInfiniteScroll from 'el-table-infinite-scroll';

@Component({
  components: {
    Footer
  },
  directives: {
    'el-table-infinite-scroll': elTableInfiniteScroll
  }
})
export default class Tracing extends Vue {
  private cc: string = '1'
  private textarea: string = ''
  private btnLoading: boolean = false
  private textStatus: boolean = true
  private fileStatus: boolean = false
  private listLoading: boolean = false
  private progmun = 0
  private clearInt = null
  private progFlag = false
  private form: SignInfoParams = {
    file: null,
    code: ''
  }

  // 上下滚动状态
  private upStatus: boolean = true
  private downStatus: boolean = true
  // 第一条和最后一条key记录
  private startKey: string = ''
  private endKey: string = ''
  // 是否下翻页
  private keyStats: boolean = false
  // 滑轮默认状态 滚动后触发上滚动事件
  private UpInitial: boolean = false

  // 表格展示状态
  private tableExist: boolean = false

  mounted() {
    // const dom = (this.$refs.multipleTable as any).bodyWrapper
    // dom.addEventListener('scroll', () => {
    //   // 滚动距离
    //   const scrollTop = dom.scrollTop
    //   // 触发滑动状态
    //   if (scrollTop > 0) {
    //     this.UpInitial = true
    //   }
    //   // 触发向上滚动事件
    //   if (scrollTop == 0 && this.UpInitial == true) {
    //     if (this.upStatus) {
    //       const params = {
    //         hashString: encodeURIComponent(this.startKey),
    //         marker: '2'
    //       }
    //       this.listLoading = true
    //       hisVerificationByString(params).then((res: any) => {
    //         console.log(res)
    //         //  处理传递参数
    //         if (res != undefined && res.data != null) {
    //           this.$message.success(res.message)
    //           for (let i = 0; i < res.data.length; i++) {
    //             this.tableData.splice(i, 0, res.data[i])
    //           }
    //           this.tableExist = true
    //           // 判断startKey是否存在
    //           this.startKey = ''
    //           if (res.obj.startKey != undefined) {
    //             this.startKey = res.obj.startKey
    //           } else {
    //             this.upStatus = false
    //           }
    //           // 修改table相对定位
    //           (this.$refs.multipleTable as any).bodyWrapper.scrollTop = (res.data.length) * 47;
    //         } else {
    //           this.upStatus = false
    //         }
    //         this.listLoading = false
    //       }).finally(() => {
    //         this.listLoading = false
    //       })
    //     } else {
    //       this.$message.warning('已经是最后一条了')
    //     }
    //     this.UpInitial = false
    //   }
    // })
  }

  // 上传文件错误提示
  private handleMsg(): void {
    this.$message.warning('只能上传一个文件！')
  }

  private tableData: Array<ITracing> = []

  //  上传文件
  private uploadFile(file: File): void {
    this.keyStats = false
    this.tableExist = false
    if (file.size / 1024 / 1024 > 10) {
      this.$message.error('文件限制小于10MB')
      return
    }
     this.progFlag=true
    this.looding()
    const formDate = new FormData()
    formDate.append('file', file)
    formDate.append('marker', '0')
    this.btnLoading = true
    hisVerification(formDate).then((res: any) => {
      console.log(res)
      //  处理传递参数
      if(res.message=='文件验证成功'){
        for( let i=0; i<res.data.length; i++ ){
              const date = new Date(res.data[i].txtime * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
              const Y = date.getFullYear() + '-';
              const M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
              const D = date.getDate() + ' ';
              const h = date.getHours() + ':';
              const m = date.getMinutes();
              res.data[i].txtime = Y+M+D+h+m;
              res.data[i].txtimeY = Y+M+D;
              res.data[i].txtimeD=h+m
               res.data[i].index = i+1
              }
        sessionStorage.setItem('tracing',JSON.stringify(res));
        sessionStorage.setItem('cincer','0');
        this.btnLoading = false
        this.progFlag = false
        clearInterval(this.clearInt);
        this.progmun = 0
        this.$router.push({path: '/info/tracing-Historical'});
       

      }
      // if (res != undefined && res.data != null) {
      //   // 重置状态
      //   this.upStatus = true
      //   this.downStatus = true
      //   this.UpInitial = false
      //   this.$message.success(res.message)
      //   this.tableData = res.data
      //   this.tableExist = true

      //   // 判断startKey与endKey是否存在
      //   console.log(res.data)
      //   if (res.obj.startkey != undefined) {
      //     this.startKey = res.obj.startkey
      //     this.keyStats = true
      //   } else {
      //     this.upStatus = false
      //   }
      //   if (res.obj.endkey != undefined) {
      //     this.endKey = res.obj.endkey
      //     this.keyStats = true
      //   } else {
      //     this.downStatus = false
      //   }
      // }
      
    }).finally(() => {
      this.btnLoading = false
      this.progFlag = false
      clearInterval(this.clearInt);
      this.progmun = 0
    })
  }

  //  文件修改
  private handleChange(file: CompleteFile): void {
    this.form.file = file.raw
  }

  //  文件移除
  private handleRemove(): void {
    this.form.file = null
  }

  // 监听
  @Watch('cc')
  radio() {
    if (this.cc == '1') {
      this.textStatus = true
      this.fileStatus = false
      return
    }
    this.textStatus = false
    this.fileStatus = true
  }
  //假的加载条
    private looding(): void{
    this.clearInt = setInterval(()=> { 
    this.progmun++; 
    if (this.progmun == 100) { 
    clearInterval(this.clearInt); 
    } 
    }, 700) 
    }

  //  文件验证
  private handleChain(): void {
    if (this.cc == '1') {
      if (!this.form.file) {
        this.$message.error('请上传文件')
        return
      }
      this.uploadFile(this.form.file)
    } else {
      if (this.textarea.length == 0) {
        this.$message.error('请填写文本内容')
        return
      }
       this.progFlag=true
       this.looding()
      this.btnLoading = true
      this.keyStats = false
      this.tableExist = false
      const params = {
        hashString: encodeURIComponent(this.textarea),
        marker: '0'
      }
      hisVerificationByString(params).then((res: any) => {
        console.log(res)
           console.log(res.data)
           for( let i=0; i<res.data.length; i++ ){

              const date = new Date(res.data[i].txtime * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
              const Y = date.getFullYear() + '-';
              const M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
              const D = date.getDate() + ' ';
              const h = date.getHours() + ':';
              const m = date.getMinutes();
              res.data[i].txtime = Y+M+D+h+m;
              res.data[i].txtimeY = Y+M+D;
              res.data[i].txtimeD=h+m
               res.data[i].index = i+1
              }
              console.log(res.data)
        sessionStorage.setItem('textarea',this.textarea);
        sessionStorage.setItem('tracing',JSON.stringify(res));
        sessionStorage.setItem('cincer','1');
        this.$router.push({path: '/info/tracing-Historical'});
        
        //  处理传递参数
        // if (res != undefined && res.data != null) {
        //   // 重置状态
        //   this.upStatus = true
        //   this.downStatus = true
        //   this.UpInitial = false
        //   this.$message.success(res.message)
        //   this.tableData = res.data
        //   this.tableExist = true
        //   // 判断startKey与endKey是否存在
        //   if (res.obj.startkey != undefined) {
        //     this.startKey = res.obj.startkey
        //     this.keyStats = true
        //   } else {
        //     this.upStatus = false
        //   }
        //   if (res.obj.endkey != undefined) {
        //     this.endKey = res.obj.endkey
        //     this.keyStats = true
        //   } else {
        //     this.downStatus = false
        //   }
        // }
        
        this.btnLoading = false
        this.progFlag = false
        clearInterval(this.clearInt);
        this.progmun = 0
      }).finally(() => {
        this.btnLoading = false
        this.progFlag = false
        clearInterval(this.clearInt);
        this.progmun = 0
      })
    }
  }

  //  滚动效果
  private load() {
    setTimeout(() => {
      if (this.keyStats) {
        this.loadingDate()
      }
    }, 200)
  }

  private loadingDate() {
    //  首次加载问题
    if (this.downStatus) {
      const params = {
        hashString: encodeURIComponent(this.endKey),
        marker: '1'
      }
      this.listLoading = true
      hisVerificationByString(params).then((res: any) => {
        console.log(res)
        //  处理传递参数
        if (res != undefined && res.data != null) {
          this.$message.success(res.message)
          for (let i = 0; i < res.data.length; i++) {
            this.tableData.push(res.data[i])
          }
          this.tableExist = true
          // 判断startKey与endKey是否存在
          this.endKey = ''
          if (res.obj.endkey != undefined) {
            this.endKey = res.obj.endkey
          } else {
            this.downStatus = false
          }
        } else {
          this.downStatus = false
        }
        this.listLoading = false
      }).finally(() => {
         this.progFlag = false
        this.listLoading = false
      })
    } else {
      this.$message.warning('已经是最后一条了')
    }
  }
}
