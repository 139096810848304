






























import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
// import { getUserInfo } from '@/api/user'
import { UserModule } from '@/store/modules/user'
import { getToken } from '@/utils/session'

/**
 * @description 导航菜单
 * @author chenanhai
 * @date 2020年8月20日09:52:08
 */
@Component
export default class Nav extends Vue {
  private activeIndex: string = ''
  private fontPath: string = '/'
  private loginFlag: boolean = true

  private menuList: Array<object> = [
    { id: '1', name: '用户管理', path: '/user' },
    // { id: '2', name: '信息存证', path: '/about2', children: [{ id: '2-1', name: '测试1', path: '/home1' }] },
    { id: '2', name: '角色管理', path: '/save-evidence' },
    { id: '6', name: '关于', path: '/about' }
  ]

  @Prop()
  private msg!: string

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  @Emit('navSend') sendMsg(navFlag: boolean) {}
  created () {
    if (getToken()) {
      this.loginFlag = false
    } else {
      this.loginFlag = true
    }
  }

  get username(): string {
    return this.$store.getters.username
  }

  private logout() {
    UserModule.logout()
    this.$router.push('/')
  }

  private activeMenu(): string {
    const route = this.$route
    const { meta, path } = route
    console.log('activeMenu', route, path)
    // if set path, the sidebar will highlight the path you set
    if (meta.activeMenu) {
      return meta.activeMenu
    }
    return path
  }

  @Watch('$route')
  routeChange(val: Route): void {
    this.activeIndex = val.path
    if (getToken()) {
      this.loginFlag = false
    } else {
      this.loginFlag = true
    }
    if (this.fontPath === this.activeIndex) {
      this.sendMsg(true)
    }
  }
}
