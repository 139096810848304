/**
 * @description 登录
 * @author chenanhai
 * @date 2020年8月26日10:42:16
 */
import router from '@/router/index'
import { UserModule } from '@/store/modules/user'
import { getToken } from '@/utils/session'

const filterRouter = ['/', '/login', '/register','/info/platform','/info/francian'];

router.beforeEach(async(to, from, next) => {
  // eslint-disable-next-line no-undef
  const hasToken = getToken()
  // console.log('router.beforeEac->', hasToken, from.path)
  if (hasToken) {
    if (!UserModule.token) {
      // UserModule.token = hasToken
      await UserModule.getUserInfo();
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      next({ ...to, replace: true })
      // next()
    } else {
      next()
    }
  } else {
    if (filterRouter.indexOf(to.path) !== -1) {
      next()
    } else {
      next({ path: '/login', query: { redirect: to.fullPath } })
    }
  }
});

// eslint-disable-next-line @typescript-eslint/no-empty-function
router.afterEach(() => {
});

