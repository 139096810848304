






































































































import { Component, Vue } from 'vue-property-decorator'
import { IGridParams, ICondictions, IPage, ICertificateInfo, ITracing } from '@/types'
import { dictSex } from '@/utils/dict'
import { sortType, unSortType } from '@/utils/util'
import Pagination from '@/components/Pagination.vue'
import { getWhereConditions, credentialWhereConditions } from '@/types/where-condition'
import { getUserGride, downloadFile, imitateHisCheckHash } from '@/api/credential'
import DigCredentialInfo from '@/views/info/DigCredentialInfo.vue';
import DigCredentialImage from '@/views/info/DigCredentialImage.vue';
import DigCredentialEdit from '@/views/info/DigCredentialEdit.vue';

@Component({
  components: {
    DigCredentialImage,
    DigCredentialInfo,
    DigCredentialEdit,
    Pagination
  },
  filters: {
    sexFilter(status: string) {
      return dictSex(status)
    }
  }
})
export default class Credential extends Vue {
  private listLoading: boolean = false;
  private isEditVisible: boolean = false
  private isImageVisible: boolean = false
  private tableData: Array<ICertificateInfo> = [];
  private form: IGridParams = {
    pageIndex: 1,
    pageItemCount: 10,
    whereConditions: [],
    orderConditions: [{ field: 'createTime', method: 'DESC' }]
  };

  public certificateInfo: ICertificateInfo = {
    id: '',
    createUserId: '',
    deleteStatus: true,
    createTime: '',
    upTime: '',
    remarks: '',
    size: '',
    hash: '',
    fileName: '',
    fileId: '',
    evidenceName: '',
    evidenceId: '',
    content: '',
    chainHash: '',
    chainHigh: '',
    chainNo1: '',
    chainNo2: '',
    saveType: ''
  };

  public certificateId?: string = '';
  private whereCondition = {
    evidenceIdEqual: ''
  };

  private sortOrders: string[] = [];
  private totalCount: number = 0;

  private btnLoading: boolean = false
  private isUpdateVisible: boolean = false
  private tracingDate: Array<ITracing> = []

  created() {
    // 获取角色数据
    this.getList();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleSort(object: any) {
    const orderType = sortType(object.order);
    const order: ICondictions = { field: object.prop, method: orderType };
    const field = object.prop;
    this.form.orderConditions = this.form.orderConditions?.filter(item => item.field != field);
    if (object.order) {
      this.form.orderConditions?.push(order);
    }
    this.getList();
    this.sortOrders = [];
    this.form.orderConditions?.forEach(item => {
      this.sortOrders.push(unSortType(item.method));
    })
  }

  private getList() {
    this.listLoading = true;
    const where = getWhereConditions(this.whereCondition, credentialWhereConditions);
    this.form.whereConditions = where;
    getUserGride(this.form).then((data: any) => {
      console.log(data)
      if (data.data) {
        this.tableData = data.data.data;
        this.totalCount = data.data.totalCount;
        this.form.pageItemCount = data.data.pageItemCount;
        this.form.pageIndex = data.data.pageIndex;
      }
    }).finally(() => {
      this.listLoading = false;
    })
  }

  private receivePage (page: IPage) {
    this.form.pageItemCount = page.pageItemCount ? page.pageItemCount : 10;
    this.form.pageIndex = page.pageIndex ? page.pageIndex : 1;
    this.getList();
  }

  public refresh () {
    this.whereCondition = {
      evidenceIdEqual: ''
    }
    this.getList();
  }

  private handleSearch() {
    console.log(this.whereCondition)
    this.form.pageIndex = 1;
    this.getList()
  }

  receiveVisible(vis: boolean) {
    this.isEditVisible = vis
  }

  receiveImageVisible(vis: boolean) {
    this.isImageVisible = vis;
  }

  receiveEditVisible(vis: boolean) {
    this.getList()
    this.isUpdateVisible = vis
  }

  //  查看
  private query(row: ICertificateInfo) {
    this.btnLoading = true
    const params = { hash: row.hash }
    imitateHisCheckHash(params).then((res: any) => {
      console.log(res)
      this.tracingDate = res.data
      this.isEditVisible = true
    }).finally(() => {
      this.btnLoading = false;
    })
  }

  //  查看证书
  private queryCertificate(row: ICertificateInfo) {
    this.certificateInfo = row;
    console.log(this.certificateInfo)
    this.isImageVisible = true;
  }

  //  替换文本
  private editCertificate(row: ICertificateInfo) {
    this.certificateInfo = row;
    console.log(this.certificateInfo)
    this.isUpdateVisible = true;
  }

  //  下载
  private download(id: string) {
    downloadFile(id).then((res: any) => {
      console.log(res)
      const content = res.data;
      const blob = new Blob([content], { type: 'application/json' }); // 构造一个blob对象来处理数据
      const fileName = res.headers.filename
      const link = document.createElement('a'); // 创建a标签
      link.download = fileName;
      link.style.display = 'none';
      link.href = URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click(); // 执行下载
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    })
  }
}
