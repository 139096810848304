/**
 * @description http 请求状态码解析
 * @author chenanhai
 * @date 2020年9月17日09:04:05
 */
export const copyright = {
  technicalSupportA: '天津证公法服科技有限公司',
  technicalSupportB: '天津市电子计算机研究所有限公司',
  record: '备案：津ICP备20002302号-1',
  recordNum: '津公网安备 12010302001878号',
  email: 'huifengxinghua@163.com',
  phone: '18822629090',
  organizer: '天津市滨海公证处',
  blockchain: '区块链备案信息:津网信备12010321426655300010号'
};

export function getCode(code: number, message: string) {
  switch (code) {
    case 200:
      return '请求成功'
    case 201:
      return message || '请求成功并且服务器创建了新的资源'
    case 202:
    case 400:
    case 402:
      return message
    case 401:
      return '（未授权）请求要求身份验证'
    case 403:
      return '当前用户无权限'
    case 404:
      return '当前请求失败，请求未找到，请检查网络'
    default:
      return '请求失败，请检查网络'
  }
}

// 性别
export const sexList = [
  { id: '0', label: '女' },
  { id: '1', label: '男' },
  { id: '2', label: '未知' }
];

// 性别
export function dictSex(type: string) {
  let sexName = '未知'
  sexList.forEach(item => {
    if (item.id === type) {
      sexName = item.label;
      return false
    }
  });
  return sexName
}
