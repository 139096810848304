




















import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { MType, Result, ResultM } from '@/types'
import { empowerUserBaseImpower, getImpoweredDataById } from '@/api/user'

@Component
export default class DlgUserDataAuth extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop({ default: [] }) userIds!: string[];
  @Prop({ default: 0 }) currMType!: number;// 用户权限

  private auth: string= '';

  private onSubmit() {
    empowerUserBaseImpower(this.auth, this.userIds).then((data: ResultM) => {
      if (data.code === 200) {
        this.$message.success(String(data.message));
        this.refresh();
        this.onClose();
      } else {
        this.$message.error('授权失败');
      }
    })
  }

  private getMType() {
    if (this.userIds.length == 1) {
      getImpoweredDataById(this.userIds[0]).then((data: Result<MType>) => {
        if (data.code === 200 && data.data) {
          this.auth = data.data.mType + ''
        }
      });
    }
  }

  private handleDisable (type: number) {
    return this.currMType < type
  }

  @Emit('update:visible')
  private onClose() {
    this.auth = '';
    return false
  }

  @Emit('refresh')
  private refresh() {}

  get dlgVisible(): boolean {
    return this.visible;
  }

  @Watch('visible')
  authStatus() {
    if (this.visible) {
      this.getMType()
    }
  }
}
