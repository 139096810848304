



















import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { IRoleInfo, ResultM } from '@/types'
import { saveRole } from '@/api/role'
import { ElForm } from 'element-ui/types/form';

@Component
export default class DlgRoleInfoEdit extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop({ default: {} }) roleInfo!: IRoleInfo;
  private form: IRoleInfo = {
    id: '',
    name: '',
    memo: '',
    deleteStatus: false,
    createUserId: '',
    leaf: false,
    createTime: '',
    updateTime: ''
  };

  private rules = {
    name: [
      { required: true, message: '请输入角色名称', trigger: 'blur' }
    ]
  };

  private title: string = '';

  private onSubmit() {
    this.updateUser('postForm');
  }

  private updateUser(formName: string) {
    (this.$refs[formName] as ElForm).validate((valid: boolean) => {
      if (valid) {
        saveRole(this.form).then((data: ResultM) => {
          let msg = ''
          if (data.message != undefined) {
            msg = data.message
          }
          if (data.code === 200) {
            this.$message.success(msg);
            this.refresh();
            this.onClose();
          } else {
            this.$message.error(msg);
          }
        });
      }
    })
  }

  @Emit('refresh')
  private refresh() {}

  @Emit('update:visible')
  private onClose() {
    return false
  }

  @Watch('visible')
  authStatus() {
    if (this.visible) {
      this.form = this.roleInfo;
      if (this.roleInfo.id) {
        this.title = '编辑角色';
      } else {
        this.title = '新增角色';
        this.form.deleteStatus = true
      }
    }
  }

  get dlgVisible(): boolean {
    return this.visible
  }
}
