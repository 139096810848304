import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import '@/router/permission'
import 'element-ui/lib/theme-chalk/index.css'
import '@/less/index.less'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'scrollName') {
    // 创建一个StorageEvent事件
    const newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}
Vue.prototype.navSetItem = function (key, newVal) {
  if (key === 'navName') {
    // 创建一个StorageEvent事件
    const newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setNavName', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}
Vue.prototype.isScoll = function (key, newVal) {
  if (key === 'isScoll') {
    // 创建一个StorageEvent事件
    const newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setIsScoll', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
