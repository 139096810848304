



































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { CompleteFile, FileResult, SignInfoParams } from '@/types'
import { uploadWitnessFile, uploadWitnessText } from '@/api/saveEvidence'
import Footer from '@/components/Footer.vue'

@Component({
  components: {
    Footer
  }
})
export default class SaveEvidence extends Vue {
  private cc = '1'
  private btnLoading: boolean = false
  private textStatus: boolean = true
  private fileStatus: boolean = false
  private textarea: string = ''
  private progmun = 0
  private clearInt = null
  private progFlag = false



  private form: SignInfoParams = {
    file: null,
    code: ''
  }

  private fileResult: FileResult = {
    id: '',
    hashKey: '',
    hashType: '',
    blockHash: '',
    blockNumber: '',
    orgcode: '',
    remark: '',
    saveType: '',
    txId: '',
    txtime: '',
    owner: '',
    createTime: '',
    previousHash:'',//上一区块hash
    realName:'',//保管人
    telephone:'',//联系方式
    idCard:'',//身份证
    fileName:'',//文件名称
    fileSize:'',//文件大小
    organization:'',//保全机构
    evidenceId:'',//编号
  }

  //  上传文件
  private uploadFile(file: File): void {
    if (file.size / 1024 / 1024 > 10) {
      this.$message.error('文件限制小于10MB')
      return
    }
    this.progFlag=true
    this.looding()
    const formDate = new FormData()
    formDate.append('file', file)
    this.btnLoading = true
    uploadWitnessFile(formDate).then(res => {
      console.log(res)
      if (res.data != null) {
        if (res.data.owner !== undefined && res.data.owner !== null) {
          this.fileResult.owner = res.data.owner
        } else {
          this.fileResult.owner = ''
        }
        if (res.data.hashKey !== undefined && res.data.hashKey !== null) {
          this.fileResult.hashKey = res.data.hashKey
        } else {
          this.fileResult.hashKey = ''
        }
        if (res.data.blockHash !== undefined && res.data.blockHash !== null) {
          this.fileResult.blockHash = res.data.blockHash
        } else {
          this.fileResult.blockHash = ''
        }
        if (res.data.blockNumber !== undefined && res.data.blockNumber !== null) {
          this.fileResult.blockNumber = res.data.blockNumber
        } else {
          this.fileResult.blockNumber = ''
        }
        if (res.data.txtime !== undefined && res.data.txtime !== null) {
          this.fileResult.txtime = res.data.txtime
        } else {
          this.fileResult.txtime = ''
        }

         if (res.data.previousHash !== undefined && res.data.previousHash !== null) {
          this.fileResult.previousHash = res.data.previousHash
        } else {
          this.fileResult.previousHash = ''
        }
         if (res.data.realName !== undefined && res.data.realName !== null) {
          this.fileResult.realName = res.data.realName
        } else {
          this.fileResult.realName = ''
        }
         if (res.data.telephone !== undefined && res.data.telephone !== null) {
          this.fileResult.telephone = res.data.telephone
        } else {
          this.fileResult.telephone = ''
        }
         if (res.data.idCard !== undefined && res.data.idCard !== null) {
          this.fileResult.idCard = res.data.idCard
        } else {
          this.fileResult.idCard = ''
        }
         if (res.data.fileName !== undefined && res.data.fileName !== null) {
          this.fileResult.fileName = res.data.fileName
        } else {
          this.fileResult.fileName = ''
        }
         if (res.data.fileSize !== undefined && res.data.fileSize !== null) {
          this.fileResult.fileSize = res.data.fileSize
        } else {
          this.fileResult.fileSize = ''
        }
         if (res.data.organization !== undefined && res.data.organization !== null) {
          this.fileResult.organization = res.data.organization
        } else {
          this.fileResult.organization = ''
        }
         if (res.data.evidenceId !== undefined && res.data.evidenceId !== null) {
          this.fileResult.evidenceId = res.data.evidenceId
        } else {
          this.fileResult.evidenceId = ''
        }
         if (res.data.txId !== undefined && res.data.txId !== null) {
          this.fileResult.txId = res.data.txId
        } else {
          this.fileResult.txId = ''
        }
         if (res.data.txId !== undefined && res.data.txId !== null) {
          this.fileResult.organType = res.data.organType
        } else {
          this.fileResult.organType = ''
        }
      }
      //  定义通知消息
      const h = this.$createElement
      // this.$notify({
      //   title: res.message,
      //   message: h('div', undefined, [
      //     h('div', '存证人：' + this.fileResult.owner),
      //     h('div', '存证Hash：' + this.fileResult.hashKey),
      //     h('div', '区块链Hash：' + this.fileResult.blockHash),
      //     h('div', '区块链高度：' + this.fileResult.blockNumber),
      //     h('div', '存证时间：' + this.fileResult.createtime)
      //   ]),
      //   type: 'success',
      //   customClass: 'notifyStyle',
      //   duration: 0
      // })
      // this.$message.success(res.message)
      this.btnLoading = false
      window.localStorage.setItem('Czmsg', JSON.stringify(this.fileResult))
      window.localStorage.setItem('cc', this.cc)
      window.localStorage.setItem('fileName', '新增存证成功')
      this.progFlag = false
      clearInterval(this.clearInt);
      this.progmun = 0
      this.$router.push({path: '/info/success'});
    }).finally(() => {
      this.btnLoading = false
      this.progFlag = false
      clearInterval(this.clearInt);
      this.progmun = 0
    })
  }

  //  文件修改
  private handleChange(file: CompleteFile): void {
    this.form.file = file.raw
  }

  //  文件移除
  private handleRemove(): void {
    this.form.file = null
  }

  // 上传文件错误提示
  private handleMsg(): void {
    this.$message.warning('只能上传一个文件！')
  }

  // 监听
  @Watch('cc')
  radio() {
    if (this.cc == '1') {
      this.textStatus = true
      this.fileStatus = false
      return
    }
    this.textStatus = false
    this.fileStatus = true
  }
  //假的加载条
    private looding(): void{
    this.clearInt = setInterval(()=> { 
    this.progmun++; 
    if (this.progmun == 100) { 
    clearInterval(this.clearInt); 
    } 
    }, 700) 
    }


  private handleChain(): void {
   
    if (this.cc == '1') {
      if (!this.form.file) {
        this.$message.error('请上传文件')
        return
      }
      this.uploadFile(this.form.file)
    } else {
      if (this.textarea.length == 0) {
        this.$message.error('请填写文本内容')
        return
      }
       this.progFlag=true
       this.looding()
      this.btnLoading = true
      // 转码处理
      const content = encodeURIComponent(this.textarea);
      uploadWitnessText(content).then((res) => {
        if (res.data != null) {
          if (res.data.owner !== undefined && res.data.owner !== null) {
            this.fileResult.owner = res.data.owner
          } else {
            this.fileResult.owner = ''
          }
          if (res.data.hashKey !== undefined && res.data.hashKey !== null) {
            this.fileResult.hashKey = res.data.hashKey
          } else {
            this.fileResult.hashKey = ''
          }
          if (res.data.blockHash !== undefined && res.data.blockHash !== null) {
            this.fileResult.blockHash = res.data.blockHash
          } else {
            this.fileResult.blockHash = ''
          }
          if (res.data.blockNumber !== undefined && res.data.blockNumber !== null) {
            this.fileResult.blockNumber = res.data.blockNumber
          } else {
            this.fileResult.blockNumber = ''
          }
           if (res.data.txtime !== undefined && res.data.txtime !== null) {
            this.fileResult.txtime = res.data.txtime
          } else {
            this.fileResult.txtime = ''
          }
          
           if (res.data.previousHash !== undefined && res.data.previousHash !== null) {
          this.fileResult.previousHash = res.data.previousHash
        } else {
          this.fileResult.previousHash = ''
        }
         if (res.data.realName !== undefined && res.data.realName !== null) {
          this.fileResult.realName = res.data.realName
        } else {
          this.fileResult.realName = ''
        }
         if (res.data.telephone !== undefined && res.data.telephone !== null) {
          this.fileResult.telephone = res.data.telephone
        } else {
          this.fileResult.telephone = ''
        }
         if (res.data.idCard !== undefined && res.data.idCard !== null) {
          this.fileResult.idCard = res.data.idCard
        } else {
          this.fileResult.idCard = ''
        }
         if (res.data.fileName !== undefined && res.data.fileName !== null) {
          this.fileResult.fileName = res.data.fileName
        } else {
          this.fileResult.fileName = ''
        }
         if (res.data.fileSize !== undefined && res.data.fileSize !== null) {
          this.fileResult.fileSize = res.data.fileSize
        } else {
          this.fileResult.fileSize = ''
        }
         if (res.data.organization !== undefined && res.data.organization !== null) {
          this.fileResult.organization = res.data.organization
        } else {
          this.fileResult.organization = ''
        }
        if (res.data.evidenceId !== undefined && res.data.evidenceId !== null) {
          this.fileResult.evidenceId = res.data.evidenceId
        } else {
          this.fileResult.evidenceId = ''
        }
         if (res.data.txId !== undefined && res.data.txId !== null) {
          this.fileResult.txId = res.data.txId
        } else {
          this.fileResult.txId = ''
        }
        if (res.data.txId !== undefined && res.data.txId !== null) {
          this.fileResult.organType = res.data.organType
        } else {
          this.fileResult.organType = ''
        }
        }

        //  定义通知消息
        // const h = this.$createElement
        // this.$notify({
        //   title: res.message,
        //   message: h('div', undefined, [
        //     h('div', '存证人：' + this.fileResult.owner),
        //     h('div', '存证Hash：' + this.fileResult.hashKey),
        //     h('div', '区块链Hash：' + this.fileResult.blockHash),
        //     h('div', '区块链高度：' + this.fileResult.blockNumber),
        //     h('div', '存证时间：' + this.fileResult.createtime)
        //   ]),
        //   type: 'success',
        //   customClass: 'notifyStyle',
        //   duration: 0
        // })
        this.btnLoading = false
        window.localStorage.setItem('Czmsg', JSON.stringify(this.fileResult))
        window.localStorage.setItem('fileName', '新增存证成功')
        this.progFlag = false
        clearInterval(this.clearInt);
        this.progmun = 0
        this.$router.push({path: '/info/success'});
      }).finally(() => {
        this.btnLoading = false
        this.progFlag = false
        clearInterval(this.clearInt);
        this.progmun = 0
      })
    }
  }
}
