import Vue from 'vue'
import Vuex from 'vuex'
import { IUser, IMenus } from '@/types'

Vue.use(Vuex);

export interface IRootState {
  user: IUser;
  menu: IMenus;
}

const getters = {
  menus: (state: IRootState) => state.menu.list,
  token: (state: IRootState) => state.user.token,
  username: (state: IRootState) => state.user.name,
  userId: (state: IRootState) => state.user.id,
};

export default new Vuex.Store<IRootState>({ getters })
