






































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { CompleteFile, FileResult, SignInfoParams } from '@/types'
import { uploadWitnessFile, uploadWitnessText } from '@/api/saveEvidence'
import Footer from '@/components/Footer.vue'
import kefu from '@/components/kefu.vue'
@Component({
  components: {
    Footer,
    kefu
  }
})
export default class SaveEvidence extends Vue {
  private isShowTop: boolean = false
  private counter: number = 0 //区块高度
  private counter1: number = 0 //一级节点
  private counter2: number = 0 //二级节点
  private counter3: number = 0 //交易数量
  private num: number = 0
  private timeT: Null = null
  private timeTfn(){
    this.timeT=setInterval(()=>{
      if(this.num >= 40){
        this.counter = '665,202';
        this.counter1 = '18';
        this.counter2 = '665,202';
        this.counter3 = '18';
        clearInterval(this.timeT)
      }else{
        this.num ++
        this.counter = Math.round(Math.random()*665202);
        this.counter1 = Math.round(Math.random()*99);
        this.counter2 = Math.round(Math.random()*665202);
        this.counter3 = Math.round(Math.random()*99);
      }
    },110)
  }
  private topBtn(){
    this.$nextTick(()=>{
      this.$el.querySelector(`#a1`).scrollIntoView({
        behavior: 'smooth',  // 平滑过渡
        block:    'start'  // 上边框与视窗顶部平齐。默认值
      });
    })
  }
  private handleScroll(){
    window.addEventListener('scroll',(e)=>{
      if(e.target.scrollTop >= 0 && e.target.scrollTop < 580){
        this.isShowTop = false
      }
      if(e.target.scrollTop > 580 && e.target.scrollTop < 1180){
        this.isShowTop = true
      }
      if(e.target.scrollTop > 2800 ){
        this.timeTfn()
      }
    },true)
  }
  mounted(){
    this.topBtn()
    this.handleScroll();
  }
}
