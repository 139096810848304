























import { Component, Vue } from 'vue-property-decorator'
import { copyright } from '@/utils/dict'


@Component
export default class Customer extends Vue {
  private display = 'none'
  private moveFn(){
    this.display = 'block'
  }
  private moveoutFn(){
    this.display = 'none'
  }
}
