













































import { Component, Vue } from 'vue-property-decorator'
import { IUserInfo } from '@/types'
import { sexList } from '@/utils/dict'
import { isEmail, isIDCard, isMobile } from '@/utils/validate'

@Component({
  components: {
  }
})
export default class Register extends Vue {
  private form: IUserInfo = {
    uHead: '',
    uName: '',
    uRealName: '',
    uSex: '',
    cardNumber: '',
    groupId: '',
    uBirthday: '',
    uTelephone: '',
    departmentName: '',
    uEmail: '',
    uAddress: '',
    uDescription: '',
    deleteStatus: false
  };

  private rules = {
    uName: [
      { required: true, message: '请输入姓名', trigger: 'blur' },
      { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
    ],
    uRealName: [
      { required: true, message: '请输入真实姓名', trigger: 'blur' },
      { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
    ],
    cardNumber: [
      { required: true, message: '请输入号', trigger: 'blur' },
      { message: '身份证号格式不正确', trigger: 'blur', validator: isIDCard }
    ],
    uEmail: [{ message: '邮箱不正确', trigger: 'blur', validator: isEmail }],
    uTelephone: [
      { required: true, message: '请输入电话号码', trigger: 'blur' },
      { message: '电话号码格式不正确', trigger: 'blur', validator: isMobile }
    ]
  };

  private sexList = sexList;

  private onSubmit() {
    console.log('-------')
  }
}

