/**
 * 菜单接口
 */
import request from '@/utils/request'
import { IGridParams, IMenuInfo } from '@/types'

// 获取菜单列表
export function getResourceTree(data: IGridParams) {
  return request({
    url: '/system/vresource/getResourceTree',
    method: 'post',
    data: data
  })
}
// 保存
export function saveMenu(data: IMenuInfo) {
  return request({
    url: '/system/resource/insert',
    method: 'post',
    data: data
  })
}
// 删除
export function deleteMenu(data: any) {
  return request({
    url: '/system/resource/deleteResource',
    method: 'post',
    data: data
  })
}
