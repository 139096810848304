





























import { Component, Vue } from 'vue-property-decorator'
import { copyright } from '@/utils/dict'


@Component
export default class Footer extends Vue {
  private copyright = copyright
}
