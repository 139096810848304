/**
 * @description 用户接口
 * @author chenanhai
 * @date 2020年8月21日11:39:04
 */
import request from '@/utils/request'

// 获取机构信息
export function getOrganizationTree() {
  return request({
    url: '/system/org/getOrganizationTree/0',
    method: 'post'
  })
}

export function getById(id: string) {
  return request({
    url: `system/org/getById/${id}`,
    method: 'get'
  })
}

export function empowerOrganizationSave(orgId: string, userIds: string[]) {
  return request({
    url: `/system/user/empowerOrganizationSave/${orgId}`,
    method: 'post',
    data: userIds
  })
}

export function getGroupTypeAll() {
  return request({
    url: '/system/grouping/getGroupTypeAll/1',
    method: 'post'
  })
}
