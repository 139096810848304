/**
 * @description 用户接口
 * @author chenanhai
 * @date 2020年8月21日11:39:04
 */
import request from '@/utils/request'
import { IGridParams, IUserInfo } from '@/types'

export function login(data: object) {
  return request({
    url: '/system/login/login',
    method: 'post',
    data: data
  })
}

// 获取用户基本信息
export function getUserInfo() {
  return request({
    url: '/system/userInfo/getCurrentUserInfo',
    method: 'post'
  })
}

// 获取用户列表
export function getUserList(data: IGridParams) {
  return request({
    url: '/system/userInfo/getGrid',
    method: 'post',
    data: data
  })
}
// 锁定用户
export function setLock(userId: string) {
  return request({
    method: 'post',
    url: `/system/userInfo/setLock/${userId}`
  })
}
// 删除用户
export function logicDeleteUser(userId: string) {
  return request({
    method: 'post',
    url: `/system/userInfo/logicDeleteUser/${userId}`
  })
}

// 导出excel
export function excelExportData(data: object) {
  return request({
    method: 'post',
    url: '/system/userInfo/excelExportData',
    data: data,
    responseType: 'blob'
  })
}

// 数据权限
export function empowerUserBaseImpower(companyId: string, userIds: string[]) {
  return request({
    method: 'post',
    url: `/system/userDataimpower/empowerUserBaseImpower/${companyId}`,
    data: userIds
  })
}
// 查询数据权限
export function getImpoweredDataById(currentUserId: string) {
  return request({
    method: 'post',
    url: `system/userDataimpower/getImpoweredDataById/${currentUserId}`
  })
}
// 更新
export function updateUserInfo(data: IUserInfo) {
  return request({
    url: '/system/userInfo/updateUserInfo',
    method: 'post',
    data: data
  })
}
// 保存
export function saveUser(data: IUserInfo) {
  return request({
    url: '/system/userInfo/saveUser',
    method: 'post',
    data: data
  })
}
// 重置密码
export function resetPassword(userId: string) {
  return request({
    url: `/system/userInfo/resetPassword/${userId}`,
    method: 'post'
  })
}
// 获取菜单
export function getMenus(data: object) {
  return request({
    url: '/system/resource/getMenus',
    method: 'post',
    data: data
  })
}
