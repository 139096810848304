






























































































































import { Vue, Component } from 'vue-property-decorator'
import { IGridParams, IPage, Result } from '@/types'
import { getGrid } from '@/api/log'
import DlgLogDetail from '@/views/system/DlgLogDetail.vue'
import Pagination from '@/components/Pagination.vue'
import { getWhereConditions, logWhereConditions } from '@/types/where-condition'

@Component({
  components: {
    DlgLogDetail,
    Pagination
  }
})
export default class LogList extends Vue {
  private listLoading: boolean = false;
  private isVisible: boolean = false;
  // private see: boolean = false;
  private type: string = '';
  private tableData: Array<any> = [];
  private detailList: Array<any> = [];
  // 时间范围
  private createTimeArr: string[] = [];
  private totalCount: number = 0;

  private form: IGridParams = {
    pageIndex: 1,
    pageItemCount: 10,
    whereConditions: [],
    orderConditions: [{ field: 'createTime', method: 'DESC' }]
  };

  private whereCondition = {
    createUserNameLike: '',
    ipLike: '',
    createTimeGreaterThanEqual: '',
    createTimeLessThanEqual: ''
  };

  created() {
    // 获取用户数据
    this.getList();
  }

  private handleSearch() {
    if (this.createTimeArr && this.createTimeArr.length > 1) {
      this.whereCondition.createTimeGreaterThanEqual = this.createTimeArr[0];
      this.whereCondition.createTimeLessThanEqual = this.createTimeArr[1].replace('00:00:00', '23:59:59');
    }
    this.getList()
  }

  private handleDetail(type: string, row: any) {
    this.detailList = row;
    this.type = type;
    this.isVisible = true;
  }

  private receivePage (page: IPage) {
    this.form.pageItemCount = page.pageItemCount ? page.pageItemCount : 10;
    this.form.pageIndex = page.pageIndex ? page.pageIndex : 1;
    this.getList();
  }

  private getList() {
    const where = getWhereConditions(this.whereCondition, logWhereConditions);
    this.form.whereConditions = where;
    this.listLoading = true;
    getGrid(this.form).then((data: Result<any>) => {
      if (data.code === 200 && data.data) {
        this.tableData = data.data.data;
        this.totalCount = data.data.totalCount;
        this.form.pageItemCount = data.data.pageItemCount;
        this.form.pageIndex = data.data.pageIndex;
      }
    }).finally(() => {
      this.listLoading = false;
    })
  }

  // private handleShow() {
  //   this.see = !this.see;
  // }

  private reset () {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = this.whereCondition;
    for (const i in obj) {
      obj[i] = '';
    }
  }
}
