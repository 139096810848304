/**
 * 文件上链存储
 */
import request from '@/utils/request'

export function uploadWitnessFile(data: object) {
  return request({
    // url: '/witnessUpload/uploadWitnessFile',
    url: '/imitateCertificate/imitateSave',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function uploadWitnessText(data: string) {
  return request({
    // url: '/witnessUpload/uploadWitnessFile',
    url: `/imitateCertificate/imitateSaveByString`,
    params: { hashString: data },
    // data: { 'hashString': data },
    method: 'post'
  })
}


