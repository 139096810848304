





























































































import { Component, Vue } from 'vue-property-decorator'
import { IGridParams, ICondictions, IPage, ICompanyInfo, ResultM } from '@/types'
import { getOrgTree, deleteOrg } from '@/api/company'
import DlgCompanyInfoEdit from '@/views/system/DlgCompanyInfoEdit'
import { dictSex } from '@/utils/dict'
import { sortType, unSortType } from '@/utils/util'
import Pagination from '@/components/Pagination.vue'
import { getWhereConditions, userWhereConditions } from '@/types/where-condition'

@Component({
  components: {
    DlgCompanyInfoEdit,
    Pagination
  },
  filters: {
    sexFilter(status: string) {
      return dictSex(status)
    }
  }
})
export default class CompanyList extends Vue {
  private listLoading: boolean = false;
  private isEditVisible: boolean = false; // 编辑
  public companyInfo: ICompanyInfo = {}; // 选中单位信息

  private tableData: Array<ICompanyInfo> = [];
  private form: IGridParams = {
    pageIndex: 1,
    pageItemCount: 10,
    whereConditions: [],
    orderConditions: [{ field: 'createTime', method: 'DESC' }]
  };

  private whereCondition = {};

  private sortOrders: string[] = [];
  private totalCount: number = 0;

  created() {
    // 获取角色数据
    this.getList();
  }

  private handleEdit(row: ICompanyInfo) {
    this.companyInfo = row;
    this.isEditVisible = true;
  }

  private handleAdd() {
    this.companyInfo = {}
    this.isEditVisible = true;
  }

  receiveVisible(vis: boolean) {
    this.isEditVisible = vis;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleSort(object: any) {
    const orderType = sortType(object.order);
    const order: ICondictions = { field: object.prop, method: orderType };
    const field = object.prop;
    this.form.orderConditions = this.form.orderConditions?.filter(item => item.field != field);
    if (object.order) {
      this.form.orderConditions?.push(order);
    }
    this.getList();
    this.sortOrders = [];
    this.form.orderConditions?.forEach(item => {
      this.sortOrders.push(unSortType(item.method));
    })
  }

  private getList() {
    this.listLoading = true;
    const where = getWhereConditions(this.whereCondition, userWhereConditions);
    this.form.whereConditions = where;
    getOrgTree(this.form).then((data: any) => {
      if (data.data) {
        this.tableData = data.data.data;
        this.totalCount = data.data.totalCount;
        this.form.pageItemCount = data.data.pageItemCount;
        this.form.pageIndex = data.data.pageIndex;
      }
    }).finally(() => {
      this.listLoading = false;
    })
  }

  private receivePage (page: IPage) {
    this.form.pageItemCount = page.pageItemCount ? page.pageItemCount : 10;
    this.form.pageIndex = page.pageIndex ? page.pageIndex : 1;
    this.getList();
  }

  public refresh () {
    this.getList();
  }

  //  删除
  private deleteMenus(id: string) {
    this.$confirm('确定删除该单位？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      deleteOrg(id).then((data: ResultM) => {
        console.log(data)
        let msg = ''
        if (data.message != undefined) {
          msg = data.message
        }
        if (data.code === 200) {
          this.$message.success(msg)
        } else {
          this.$message.error(msg);
        }
        this.getList();
      })
    })
  }
}
