import { RouteConfig } from 'vue-router'

const systemRouter: Array<RouteConfig> = [
  {
    path: '/tfkjcah12345ccaba/user',
    name: 'UserList',
    meta: {
      sIcon: 'profile',
      sKey: 'user'
    },
    component: () => import(/* webpackChunkName: "userList" */ '@/views/system/UserList.vue')
  },
  {
    path: '/tfkjcah12345ccaba/role',
    name: 'RoleList',
    meta: {
      sIcon: 'profile',
      sKey: 'role'
    },
    component: () => import(/* webpackChunkName: "roleList" */ '@/views/system/RoleList.vue')
  },
  {
    path: '/tfkjcah12345ccaba/menu',
    name: 'MenuList',
    meta: {
      sIcon: 'profile',
      sKey: 'menu'
    },
    component: () => import(/* webpackChunkName: "menuList" */ '@/views/system/MenuList.vue')
  },
  {
    path: '/tfkjcah12345ccaba/company',
    name: 'CompanyList',
    meta: {
      sIcon: 'profile',
      sKey: 'company'
    },
    component: () => import(/* webpackChunkName: "CompanyList" */ '@/views/system/CompanyList.vue')
  },
  {
    path: '/tfkjcah12345ccaba/log',
    name: 'LogList',
    meta: {
      sIcon: 'profile',
      sKey: 'log'
    },
    component: () => import(/* webpackChunkName: "logList" */ '@/views/system/LogList.vue')
  },
  {
    path: '/tfkjcah12345ccaba/dictionary',
    name: 'DictList',
    meta: {
      sIcon: 'profile',
      sKey: 'dictionary'
    },
    component: () => import(/* webpackChunkName: "dictList" */ '@/views/system/LogList.vue')
  }
];

export default systemRouter
