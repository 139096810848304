/**
 * Created by Admin
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path: string) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url: string) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str: string) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str: string) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str: string) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email: string) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

export function isEmail(rule: { messages: string }, value: string, callback: any) {
  if (!validEmail(value)) {
    const longError = rule.messages;
    return callback(new Error(longError));
  } else {
    callback();
  }
  return true;
}

/**
 * @param {string} idCard
 * @returns {Boolean}
 */
export function validIDCard(idCard: string) {
  const reg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  return reg.test(idCard)
}

/**
 * @param {string} rule
 * @param {string} value
 * @param {any} callback
 * @returns {Boolean}
 */
export function isIDCard(rule: { messages: string }, value: string, callback: any) {
  if (!validIDCard(value)) {
    const longError = rule.messages;
    return callback(new Error(longError));
  } else {
    callback();
  }
  return true;
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg: []) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

/**
 * 验证是否为number
 * @param decimal
 * @returns {boolean}
 */
export function validNumber(decimal: string) {
  const reg = /^(([0-9]+\d*)|([0-9]+\d*\.[0-9]\d*))$/;
  return reg.test(decimal)
}

/**
 * 验证是否为number
 * @param decimal
 * @returns {boolean}
 */
export function validMobile(mobile: string) {
  const reg = /^1[3456789]\d{9}$/;
  return reg.test(mobile)
}

export function isMobile(rule: { messages: string }, value: string, callback: any) {
  if (!validMobile(value)) {
    const longError = rule.messages;
    return callback(new Error(longError));
  } else {
    callback();
  }
  return true;
}
