































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { ICompanyInfo, ResultM } from '@/types'
import { addOrEditOrg } from '@/api/company'
import { ElForm } from 'element-ui/types/form';

@Component
export default class DlgCompanyInfoEdit extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop({ default: {} }) companyInfo!: ICompanyInfo;
  private form: ICompanyInfo = {
    id: null,
    oName: '',
    oParentId: null,
    oParentName: null,
    oType: 0,
    dIdOrgClass: 'bd12d6b7-adbd-45b6-8f4e-0d1233a56a8e', // 默认单位分类
    oShortName: '',
    oContact: '',
    oTel: '',
    cityId: null,
    oAddress: '',
    oIndex: 0,
    deleteStatus: true,
    createUserId: null,
    createTime: null
  };

  private rules = {
    oName: [
      { required: true, message: '请输入单位名称', trigger: 'blur' }
    ]
  };

  private title: string = '';

  private onSubmit() {
    this.updateUser('postForm');
  }

  private updateUser(formName: string) {
    (this.$refs[formName] as ElForm).validate((valid: boolean) => {
      if (valid) {
        addOrEditOrg(this.form).then((data: ResultM) => {
          let msg = ''
          if (data.message != undefined) {
            msg = data.message
          }
          if (data.code === 200) {
            this.$message.success(msg);
            this.refresh();
            this.onClose();
          } else {
            this.$message.error(msg);
          }
        });
      }
    })
  }

  @Emit('refresh')
  private refresh() {}

  @Emit('update:visible')
  private onClose() {
    return false
  }

  @Watch('visible')
  authStatus() {
    // 清除验证
    const formName = 'postForm'
    this.$nextTick(() => {
      (this.$refs[formName] as ElForm).clearValidate();
    })
    if (this.visible) {
      if (this.companyInfo.id) {
        this.title = '编辑单位';
        this.form = this.companyInfo;
      } else {
        this.title = '新增单位';
        this.form = {
          id: null,
          oName: '',
          oParentId: null,
          oParentName: null,
          oType: 0,
          dIdOrgClass: 'bd12d6b7-adbd-45b6-8f4e-0d1233a56a8e', // 默认单位分类
          oShortName: '',
          oContact: '',
          oTel: '',
          cityId: null,
          oAddress: '',
          oIndex: 0,
          deleteStatus: true,
          createUserId: null,
          createTime: null
        }
      }
      console.log(this.form)
    }
  }

  get dlgVisible(): boolean {
    return this.visible
  }
}
