/**
 * 资源管理接口
 */
import request from '@/utils/request'

// 获取角色所有资源树
export function getResourceRoleTree() {
  return request({
    url: '/system/resource/getResourceRoleTree',
    method: 'post'
  })
}
// 获取已授权的资源
export function getRoleResource(roleId: string) {
  return request({
    url: `/system/resource/getRoleResource/${roleId}`,
    method: 'post'
  })
}
