/**
 * 角色接口
 */
import request from '@/utils/request'
import { IGridParams, IRoleInfo } from '@/types'

// 获取角色列表
export function getRoleList(data: IGridParams) {
  return request({
    url: '/system/role/getGrid',
    method: 'post',
    data: data
  })
}
// 保存
export function saveRole(data: IRoleInfo) {
  return request({
    url: '/system/role/addRole',
    method: 'post',
    data: data
  })
}
// 删除
export function deleteRole(data: any) {
  return request({
    url: '/system/role/deleteRoles',
    method: 'post',
    data: data
  })
}
// 资源授权
export function empowerResources(roleId: string, data: any) {
  return request({
    url: `/system/role/empowerResources/${roleId}`,
    method: 'post',
    data: data
  })
}
// 获取用户数据
export function initRoleUsers() {
  return request({
    url: '/system/role/initRoleUsers',
    method: 'post'
  })
}
// 根据角色id获取角色已分配人员
export function getRoleSelectedUsers(roleId: string) {
  return request({
    url: `/system/role/getRoleSelectedUsers/${roleId}`,
    method: 'post'
  })
}
// 角色分配人员
export function saveRoleSelectedUsers(roleId: string, data: any) {
  return request({
    url: `/system/role/saveRoleSelectedUsers/${roleId}`,
    method: 'post',
    data: data
  })
}
