








































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { SignInfoParams, CompleteFile, FileResult } from '@/types'
import { sendSms, witnessesVerify, witnessesTextVerify } from '@/api/fileCheck'
import DigCheckInfo from '@/views/info/DigCheckInfo.vue'
import Footer from '@/components/Footer.vue'
import { dowmCertifid, dowmCertifile, uploadTemplateFile } from '@/api/credential';

@Component({
  components: {
    DigCheckInfo,
    Footer
  }
})
export default class FileCheck extends Vue {
  private btnStatus = false
  private smsStatus = true
  private smsHint: string = '发送短信'
  private smsId: string = ''
  private cc: string = '1'
  private textarea: string = ''
  private btnLoading: boolean = false
  private isEditVisible: boolean = false
  private textStatus: boolean = true
  private fileStatus: boolean = false
  private iconBase64: any = ''
  private progmun = 0
  private clearInt = null
  private progFlag = false
  private form: SignInfoParams = {
    file: null,
    code: ''
  }

  private fileResult: FileResult = {
    id: '',
    hashKey: '',
    hashType: '',
    blockHash: '',
    blockNumber: '',
    orgcode: '',
    remark: '',
    saveType: '',
    txId: '',
    txTime: '',
    owner: '',
    createTime: ''
  }

  // 上传文件错误提示
  private handleMsg(): void {
    this.$message.warning('只能上传一个文件！')
  }

  //  上传文件
  private uploadFile(file: File): void {
    if (file.size / 1024 / 1024 > 10) {
      this.$message.error('文件限制小于10MB')
      return
    }
    this.progFlag=true
    this.looding()
    const formDate = new FormData()
    formDate.append('file', file)
    this.btnLoading = true
    // this.getBase64(file)
    witnessesVerify(formDate).then(res => {
      console.log(res)
      //  处理传递参数
      if (res != undefined && res.data != null) {
        this.fileResult = res.data
        this.$message.success('文件验证成功')
        // 去掉下载变成上传文件
        // this.downloadByFile(file, res.data.txTime)
        this.uploadTemplateFile(file)

        // this.isEditVisible = true
      }
      this.btnLoading = false
      this.progFlag = false
      clearInterval(this.clearInt);
      this.progmun = 0
    }).finally(() => {
      this.btnLoading = false
      this.progFlag = false
      clearInterval(this.clearInt);
      this.progmun = 0
    })
  }

  receiveVisible(vis: boolean) {
    this.isEditVisible = vis
  }

  //  文件修改
  private handleChange(file: CompleteFile): void {
    this.form.file = file.raw
  }

  //  文件移除
  private handleRemove(): void {
    this.form.file = null
  }

  // 监听
  @Watch('cc')
  radio() {
    if (this.cc == '1') {
      this.textStatus = true
      this.fileStatus = false
      return
    }
    this.textStatus = false
    this.fileStatus = true
  }
  //假的加载条
    private looding(): void{
    this.clearInt = setInterval(()=> { 
    this.progmun++; 
    if (this.progmun == 100) { 
    clearInterval(this.clearInt); 
    } 
    }, 700) 
    }

  //  文件验证
  private handleChain(): void {
   
    if (this.cc == '1') {
      if (!this.form.file) {
        this.$message.error('请上传文件')
        return
      }
      this.uploadFile(this.form.file)
    } else {
      if (this.textarea.length == 0) {
        this.$message.error('请填写文本内容')
        return
      }
       this.progFlag=true
       this.looding()
      this.btnLoading = true
      // 转码处理
      const content = encodeURIComponent(this.textarea);
      witnessesTextVerify(content).then((res) => {
        console.log(res)
        //  处理传递参数
        if (res != undefined && res.data != null) {
          this.fileResult = res.data
          this.$message.success(res.message)
          // this.downloadByFile(null, res.data.txTime)
          const params = {
            'type': this.cc,
            'content': this.textarea
          }
          window.localStorage.setItem('down', JSON.stringify(params))
          window.localStorage.setItem('cc', this.cc)
          window.localStorage.setItem('Czmsg', JSON.stringify(res.data))
          window.localStorage.setItem('fileName', '区块验证成功')
          this.$router.push({path: '/info/success'});
          // this.isEditVisible = true
        }
        this.btnLoading = false
        this.progFlag = false
        clearInterval(this.clearInt);
        this.progmun = 0
      }).finally(() => {
        this.btnLoading = false
        this.progFlag = false
        clearInterval(this.clearInt);
        this.progmun = 0
      })
    }
  }

  //  短信验证
  private smsCode(): void {
    //  输入框状态
    this.smsStatus = false
    //  按钮状态
    this.btnStatus = true
    //  发送短信
    sendSms().then(res => {
      console.log(res.data)
      //  获取验证码返回值
      this.smsId = res.data
    })
    //  倒计时重新发送
    let time: number = 60
    const interval = window.setInterval(() => {
      this.smsHint = '（' + time + '秒）后重新发送'
      time--
      if (time < 0) {
        this.smsHint = '重新发送'
        time = 60
        this.btnStatus = false
        window.clearInterval(interval)
      }
    }, 1000)
  }

  private downloadByFile(file: any, date: any) {
    if (this.cc == '1') {
      const formDate = new FormData()
      formDate.append('file', file)
      dowmCertifile(formDate).then((res: any) => {
        console.log(res)
        const content = res.data;
        const blob = new Blob([content], { type: 'application/octet-stream' }); // 构造一个blob对象来处理数据
        const time = this.timestampToTime(date)
        const fileName = 'JBC' + time + '.pdf'
        const link = document.createElement('a'); // 创建a标签
        link.download = fileName;
        link.style.display = 'none';
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click(); // 执行下载
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }).finally(() => {
        this.btnLoading = false
        this.progFlag = false
        clearInterval(this.clearInt);
        this.progmun = 0
      })
    } else {
      const id = encodeURIComponent(this.textarea)
      dowmCertifid(id).then((res: any) => {
        const content = res.data;
        const blob = new Blob([content], { type: 'application/octet-stream' }); // 构造一个blob对象来处理数据
        // const fileName = this.certificateInfo.evidenceId + '.pdf'
        const time = this.timestampToTime(date)
        const fileName = 'JBC' + time + '.pdf'
        const link = document.createElement('a'); // 创建a标签
        link.download = fileName;
        link.style.display = 'none';
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click(); // 执行下载
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }).finally(() => {
        this.btnLoading = false
        this.progFlag = false
        clearInterval(this.clearInt);
        this.progmun = 0
      })
    }
  }

  private timestampToTime(row: any) {
    const date = new Date(row)
    return date.getTime()
  }

  private uploadTemplateFile(file: File) {

    const formDate = new FormData()
    formDate.append('file', file)
    console.log(formDate,'555555')
    uploadTemplateFile(formDate).then((res: any) => {
      console.log(res)
      const params = {
        'type': this.cc,
        'file': res.data
      }
      window.localStorage.setItem('down', JSON.stringify(params))
      window.localStorage.setItem('Czmsg', JSON.stringify(this.fileResult))
      window.localStorage.setItem('fileName', '区块验证成功')
      this.$router.push({path: '/info/success'});
    }).finally(() => {

    })
  }
}
