

































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { Result, ResultM } from '@/types'
import { empowerOrganizationSave, getById, getOrganizationTree } from '@/api/organization'

@Component
export default class DlgUserCompany extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop({ default: [] }) userIds!: string[];
  private form = {
    title: '',
    type: '',
    key: ''
  };

  private treeData: [] = [];

  private defaultProps = {
    children: 'children',
    label: 'title'
  };

  private filterText: string= '';

  filterNode(value: string, data: {title: ''}) {
    if (!value) return true;
    return data.title.indexOf(value) !== -1;
  }

  private onSubmit() {
    empowerOrganizationSave(this.form.key, this.userIds).then((data: ResultM) => {
      if (data.code === 200) {
        this.$message.success('授权成功!');
        this.refresh();
        this.onClose();
      } else {
        this.$message.error('授权失败!');
      }
    })
  }

  @Emit('update:visible')
  private onClose() {
    this.form.title = '';
    this.form.type = '';
    this.form.key = '';
    return false
  }

  get dlgVisible(): boolean {
    return this.visible;
  }

  private handleNodeClick(row: any) {
    getById(row.key).then((data: Result<any>) => {
      if (data.code === 200) {
        this.form.title = data.data.oName;
        this.form.key = data.data.id;
        this.form.type = data.data.oType === 0 ? '单位' : '部门';
      }
    })
  }

  @Watch('visible')
  authStatus() {
    if (this.visible) {
      this.getOrganizationTree()
    }
  }

  @Watch('filterText')
  filterTree(val: any) {
    const tree: any = this.$refs.tree
    tree.filter(val);
  }

  private getOrganizationTree() {
    getOrganizationTree().then((data: Result<any>) => {
      if (data.code) {
        this.treeData = data.data
      }
    })
  }

  @Emit('refresh')
  private refresh() {}
}
