































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { CompleteFile, ICertificateInfo, SignInfoParams } from '@/types'
import { imitateUpdate, imitateUpdateByString } from '@/api/credential';

@Component
export default class DigCredentialEdit extends Vue {
  @Prop({default: false}) visible!: boolean
  @Prop({default: false}) certificateInfo!: ICertificateInfo

  private title: string = '更新'
  private btnLoading: boolean = false
  private textarea: string = ''
  private form: SignInfoParams = {
    file: null,
    code: ''
  }

  @Emit('receiveVisible')
  private onClose() {
    this.textarea = '';
    if (this.$refs.upload as any != undefined) {
      (this.$refs.upload as any).clearFiles()
    }
    return false
  }

  get dlgVisible(): boolean {
    return this.visible
  }

  // 上传文件错误提示
  private handleMsg(): void {
    this.$message.warning('只能上传一个文件！')
  }

  //  上传文件
  private uploadFile(file: File): void {
    if (file.size / 1024 / 1024 > 10) {
      this.$message.error('文件限制小于10MB')
      return
    }
    const formDate = new FormData()
    formDate.append('file', file)
    formDate.append('hisKey', this.certificateInfo.hash)
    this.btnLoading = true
    imitateUpdate(formDate).then((res: any) => {
      console.log(res)
      //  处理传递参数
      if (res != undefined && res.data != null) {
        this.$message.success(res.message)
        this.onClose();
      }
    }).finally(() => {
      this.btnLoading = false
    })
  }

  //  文件修改
  private handleChange(file: CompleteFile): void {
    this.form.file = file.raw
  }

  //  文件移除
  private handleRemove(): void {
    this.form.file = null
  }

  //  文件验证
  private handleChain(): void {
    if (this.certificateInfo.saveType === 'FILE') {
      if (!this.form.file) {
        this.$message.error('请上传文件')
        return
      }
      this.uploadFile(this.form.file)
    } else {
      if (this.textarea == '' || this.textarea == null) {
        this.$message.error('请输入文本内容')
        return
      }
      const params = {
        hashString: encodeURIComponent(this.textarea),
        hisKey: this.certificateInfo.hash
      }
      this.btnLoading = true
      imitateUpdateByString(params).then((res: any) => {
        if (res != undefined && res.data != null) {
          this.$message.success(res.message)
          this.onClose();
        }
      }).finally(() => {
        this.btnLoading = false
      })
    }
  }
}
