/**
 * @description 路由设置
 * @author chenanhai
 * @date 2020年8月20日09:52:08
 */
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
// import systemRouter from '@/router/system'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "index" */ '@/views/login/Index.vue')
  },
  {
    path: '/back',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
  },
  {
    path: '/save-evidence',
    name: 'SaveEvidence',
    component: () => import(/* webpackChunkName: "saveEvidence" */ '@/views/info/SaveEvidence.vue')
  },
  {
    path: '/file-check',
    name: 'FileCheck',
    component: () => import(/* webpackChunkName: "fileCheck" */ '@/views/info/FileCheck.vue')
  },
  {
    path: '/hash-check',
    name: 'HashCheck',
    component: () => import(/* webpackChunkName: "hashCheck" */ '@/views/info/HashCheck.vue')
  },
  {
    path: '/credential',
    name: 'Credential',
    component: () => import(/* webpackChunkName: "credential" */ '@/views/info/Credential.vue')
  },
  {
    path: '/tracing',
    name: 'Tracing',
    component: () => import(/* webpackChunkName: "tracing" */ '@/views/info/Tracing.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '@/views/Register.vue')
  },
  {
    path: '/error/404',
    name: 'Page404',
    component: () => import(/* webpackChunkName: "page404" */ '@/views/error/404.vue')
  },
  {
    path: '/info/success',
    name: 'success',
    component: () => import(/* webpackChunkName: "success" */ '@/views/info/success.vue')
  },
  {
    path: '/info/platform',
    name: 'platform',
    component: () => import(/* webpackChunkName: "platform" */ '@/views/info/platform.vue')
  },
  {
    path: '/info/francian',
    name: 'francian',
    component: () => import(/* webpackChunkName: "francian" */ '@/views/info/francian.vue')
  },
  {
    path: '/info/tracing-Historical',
    name: 'tracingHistorical',
    component: () => import(/* webpackChunkName: "francian" */ '@/views/info/tracingHistorical.vue')
  }
  // ...systemRouter
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
});

const originalPush = VueRouter.prototype.push;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
VueRouter.prototype.push = function push(location: any) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return originalPush.call(this, location).catch((err: any) => err)
};

export default router
