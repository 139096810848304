






















































































import { Component, Vue } from 'vue-property-decorator'
import { IGridParams, ICondictions, IPage, IMenuInfo, ResultM } from '@/types'
import { getResourceTree, deleteMenu } from '@/api/menu'
import DlgMenuInfoEdit from '@/views/system/DlgMenuInfoEdit'
import { dictSex } from '@/utils/dict'
import { sortType, unSortType } from '@/utils/util'
import Pagination from '@/components/Pagination.vue'
import { getWhereConditions, userWhereConditions } from '@/types/where-condition'

@Component({
  components: {
    DlgMenuInfoEdit,
    Pagination
  },
  filters: {
    sexFilter(status: string) {
      return dictSex(status)
    }
  }
})
export default class MenuList extends Vue {
  private listLoading: boolean = false;
  private isEditVisible: boolean = false; // 编辑
  public menuIds: string[] = []; // 选中菜单id
  public menuInfo: IMenuInfo = {}; // 选中菜单id
  public menuList: Array<IMenuInfo> = [] // 选中菜单删除列表
  public btnDisabled: boolean = true; // 按钮选中

  private tableData: Array<IMenuInfo> = [];
  private form: IGridParams = {
    pageIndex: 1,
    pageItemCount: 10,
    whereConditions: [],
    orderConditions: [{ field: 'createTime', method: 'DESC' }]
  };

  private whereCondition = {};

  private sortOrders: string[] = [];
  private totalCount: number = 0;

  created() {
    // 获取角色数据
    this.getList();
  }

  private handleEdit(row: IMenuInfo) {
    this.menuInfo = row;
    this.isEditVisible = true;
  }

  private handleAdd() {
    this.menuInfo = {}
    this.isEditVisible = true;
  }

  receiveVisible(vis: boolean) {
    this.isEditVisible = vis;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleSort(object: any) {
    const orderType = sortType(object.order);
    const order: ICondictions = { field: object.prop, method: orderType };
    const field = object.prop;
    this.form.orderConditions = this.form.orderConditions?.filter(item => item.field != field);
    if (object.order) {
      this.form.orderConditions?.push(order);
    }
    this.getList();
    this.sortOrders = [];
    this.form.orderConditions?.forEach(item => {
      this.sortOrders.push(unSortType(item.method));
    })
  }

  private getList() {
    this.listLoading = true;
    const where = getWhereConditions(this.whereCondition, userWhereConditions);
    this.form.whereConditions = where;
    getResourceTree(this.form).then((data: any) => {
      if (data.data) {
        this.tableData = data.data.data;
        this.totalCount = data.data.totalCount;
        this.form.pageItemCount = data.data.pageItemCount;
        this.form.pageIndex = data.data.pageIndex;
      }
    }).finally(() => {
      this.listLoading = false;
    })
  }

  private receivePage (page: IPage) {
    this.form.pageItemCount = page.pageItemCount ? page.pageItemCount : 10;
    this.form.pageIndex = page.pageIndex ? page.pageIndex : 1;
    this.getList();
  }

  public refresh () {
    this.getList();
  }

  // 选中控制 btn
  private handleSelectionChange(row: Array<IMenuInfo>) {
    this.menuList = row
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selArr: any = this.$refs.multipleTable;
    this.menuIds = [];
    selArr.selection.forEach((item: { id: string | number | undefined }) => {
      this.menuIds.push(String(item.id))
    });

    if (this.menuIds.length > 0) {
      this.btnDisabled = false
    } else {
      this.btnDisabled = true
    }
  }

  //  删除
  private deleteMenus() {
    this.$confirm('确定删除选中菜单？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      deleteMenu(this.menuList).then((data: ResultM) => {
        console.log(data)
        let msg = ''
        if (data.message != undefined) {
          msg = data.message
        }
        if (data.code === 200) {
          this.$message.success(msg)
        } else {
          this.$message.error(msg);
        }
        this.getList();
      })
    })
  }
}
