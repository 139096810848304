



































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { IUserInfo, Result, ResultM } from '@/types'
import { isEmail, isIDCard, isMobile } from '@/utils/validate'
import { sexList } from '@/utils/dict'
import { getGroupTypeAll } from '@/api/organization'
import { updateUserInfo, saveUser } from '@/api/user'

@Component
export default class DlgUserInfoEdit extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop({ default: {} }) userInfo!: IUserInfo;
  private form: IUserInfo = {
    uHead: '',
    uName: '',
    uRealName: '',
    uSex: '',
    cardNumber: '',
    groupId: '',
    uBirthday: '',
    uTelephone: '',
    departmentName: '',
    uEmail: '',
    uAddress: '',
    uDescription: '',
    deleteStatus: false
  };

  private rules = {
    uName: [
      { required: true, message: '请输入姓名', trigger: 'blur' },
      { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
    ],
    uRealName: [
      { required: true, message: '请输入真实姓名', trigger: 'blur' },
      { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
    ],
    cardNumber: [
      { required: true, message: '请输入号', trigger: 'blur' },
      { message: '身份证号格式不正确', trigger: 'blur', validator: isIDCard }
    ],
    uEmail: [{ message: '邮箱不正确', trigger: 'blur', validator: isEmail }],
    uTelephone: [
      { required: true, message: '请输入电话号码', trigger: 'blur' },
      { message: '电话号码格式不正确', trigger: 'blur', validator: isMobile }
    ]
  };

  private title: string = '';
  private sexList = sexList;
  private groupList = [];

  private onSubmit() {
    if (this.form.id) {
      this.updateUser();
    } else {
      this.addUser();
    }
  }

  private updateUser() {
    updateUserInfo(this.form).then((data: ResultM) => {
      if (data.code === 200) {
        this.$message.success('更新成功!');
        this.refresh();
        this.onClose();
      } else {
        this.$message.error('更新失败!');
      }
    });
  }

  private addUser() {
    saveUser(this.form).then((data: ResultM) => {
      if (data.code === 200) {
        this.$message.success('新增成功!');
        this.refresh();
        this.onClose();
      } else {
        this.$message.error('新增失败!');
      }
    });
    return false;
  }

  @Emit('refresh')
  private refresh() {}

  @Emit('update:visible')
  private onClose() {
    return false
  }

  @Watch('visible')
  authStatus() {
    this.getGroupTypeAll();
    if (this.visible) {
      this.form = this.userInfo;
      if (this.userInfo.id) {
        this.title = '编辑用户';
      } else {
        this.title = '新增用户';
        this.form.deleteStatus = true
      }
    }
  }

  get dlgVisible(): boolean {
    return this.visible
  }

  private getGroupTypeAll() {
    getGroupTypeAll().then((data: Result<any>) => {
      if (data.code) {
        this.groupList = data.data
      }
    })
  }
}
