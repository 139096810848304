



































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { ImageType } from '@/types'
import { copyright } from '@/utils/dict'
import { UserModule } from '@/store/modules/user'
import kefu from '@/components/kefu.vue'
import Footer from '@/components/Footer.vue'

@Component({
  components: {
    kefu,
    Footer
  }
})
export default class Home extends Vue {
  private copyright = copyright
  private imgTitle: Array<ImageType> = [
    { title: '存证平台全过程参与鉴证', remark: '有效提升证明效力' },
    { title: '存证平台能力开发', remark: '快速实现对接赋能' },
    { title: '存证平台客户端', remark: '线上线下，随时随地都能用' }
  ]
  private stFn(num){
    switch (num) {
      case 1:
        this.$refs.stImg1.style.top = '-20px'
        break;
      case 2:
        this.$refs.stImg2.style.right = '21px'
        break;
      case 3:
        this.$refs.stImg3.style.left = '139px'
        break;
      case 4:
        this.$refs.stImg4.style.left = '23px'
        break;
      case 5:
        this.$refs.stImg5.style.right = '108px'
        break;
      default:
        break;
    }
  }
  private outFn(num){
    switch (num) {
      case 1:
        this.$refs.stImg1.style.top = '-100px'
        break;
      case 2:
        this.$refs.stImg2.style.right = '-100px'
        break;
      case 3:
        this.$refs.stImg3.style.left = '-139px'
        break;
      case 4:
        this.$refs.stImg4.style.left = '-100px'
        break;
      case 5:
        this.$refs.stImg5.style.right = '-139px'
        break;
      default:
        break;
    }
  }

  private linkBtn() {
    // this.$router.push(`tracing`);
    this.$nextTick(()=>{
      this.$el.querySelector(`#a2`).scrollIntoView({
        behavior: 'smooth',  // 平滑过渡
        block:    'start'  // 上边框与视窗顶部平齐。默认值
      });
    })
  }
  private topBtn(){
    this.$nextTick(()=>{
      this.$el.querySelector(`#a1`).scrollIntoView({
        behavior: 'smooth',  // 平滑过渡
        block:    'start'  // 上边框与视窗顶部平齐。默认值
      });
    })
  }
  private isShowTop: boolean = false
  private counter: number = 0 //服务用户
  private counter1: number = 0 //服务机构
  private counter2: number = 0 //生命周期
  private num: number = 0
  private timeT: Null = null
  private timeTfn(){
    this.timeT=setInterval(()=>{
      if(this.num >= 40){
        this.counter = 4958;
        this.counter1 = 1478;
        this.counter2 = 100;
        clearInterval(this.timeT)
      }else{
        this.num ++
        this.counter = Math.round(Math.random()*5000);
        this.counter1 = Math.round(Math.random()*2000);
        this.counter2 = Math.round(Math.random()*100);
      }
    },50)
  }
  private handleScroll(){
    // const st = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    // console.log(st)
    // if(st>380){
    //   this.timeTfn()
    // }
    window.addEventListener('scroll',(e)=>{
      if(e.target.scrollTop>380){
        this.timeTfn()
      }
      if(e.target.scrollTop >= 0 && e.target.scrollTop < 580){
        this.navSetItem('navName','1');
        this.isShowTop = false
      }
      if(e.target.scrollTop > 580 && e.target.scrollTop < 1180){
        this.navSetItem('navName','2');
        this.isShowTop = true
      }
      if(e.target.scrollTop > 1180 && e.target.scrollTop < 1947){
        this.navSetItem('navName','3');
      }
      if(e.target.scrollTop > 1947 ){
        this.navSetItem('navName','4');
      }
    },true)
  }
  private more(){
    this.isScoll('isScoll','false');
    this.$router.push(`/info/francian`);
  }
  private more2(){
    this.isScoll('isScoll','false');
    this.$router.push(`/info/platform`);
  }

  mounted(){
    window.localStorage.setItem('isScoll','true')
    this.handleScroll();
    window.addEventListener('setItem', ()=> {
      console.log(sessionStorage.getItem('scrollName'))
      this.$nextTick(()=>{
        this.$el.querySelector(`#${sessionStorage.getItem('scrollName')}`).scrollIntoView({
          behavior: 'smooth',  // 平滑过渡
          block:    'start'  // 上边框与视窗顶部平齐。默认值
        });
      })
    })
  }
}




