/**
 * @description 用户接口
 * @author chenanhai
 * @date 2020年8月24日10:13:13
 */
import { VuexModule, Module, getModule, Action, Mutation } from 'vuex-module-decorators'
import store from '@/store'
import { getMenus } from '@/api/user'
import { getToken } from '@/utils/session'
import { IMenus, INavMenu } from '@/types'
import systemRouter from '@/router/system'
import { RouteConfig } from 'vue-router';
const system = [
  { path: '/system/', name: 'System', meta: { sIcon: 'profile', sKey: 'system' } },
  { path: '/data/', name: 'Data', meta: { sIcon: 'profile', sKey: 'data' } }
];

function hasPermission(menus: Array<any>, route: RouteConfig) {
  if (route.meta && route.meta.sKey) {
    return menus.some((menu: any) => menu.map.sKey === route.meta.sKey)
  } else {
    return false
  }
}

function filterAsyncRoutes(routes: any, menus: Array<any>) {
  const res: Array<any> = [];
  routes.forEach((route: RouteConfig) => {
    const tmp: any = { ...route };
    if (hasPermission(menus, route)) {
      if (route.children) {
        tmp.children = filterAsyncRoutes(tmp.children, menus)
      }
    }
    res.push(tmp)
  });
  return res;
}
function setMenuPath(routes: any, key: string) {
  let path: string = '/error/404';
  routes.forEach((route: RouteConfig) => {
    const tmp: any = { ...route };
    if (tmp.meta && tmp.meta.sKey && tmp.path && key === tmp.meta.sKey) {
      path = tmp.path;
      return false;
    }
  });
  return path;
}

function filterAsyncSetMenu(routes: any, menus: Array<any>) {
  menus.forEach((menu: INavMenu) => {
    const tmp: any = { ...menu };
    if (tmp.map && tmp.map.sKey) {
      menu.path = setMenuPath(routes, tmp.map.sKey)
    }
    if (tmp.children) {
      filterAsyncSetMenu(routes, tmp.children)
    }
  });
  return true;
}

@Module({ dynamic: true, store, name: 'menu' })
class Menu extends VuexModule implements IMenus {
  public list: Array<any> = [];

  @Mutation
  private SET_MENU(menus: Array<any>) {
    this.list = menus
  }

  @Action({ rawError: true })
  public async getMenus() {
    if (getToken()) {
      const { data } = await getMenus({ type: '1' });
      filterAsyncSetMenu(systemRouter, data)
      this.SET_MENU(data);
      return true
    } else {
      return false
    }
  }

  @Action({ rawError: true })
  generateRoutes(menus: Array<any>) {
    return new Promise(resolve => {
      const routes = [...systemRouter, ...system]; // 强制匹配（system菜单类型不一致）
      const accessedRoutes = filterAsyncRoutes(routes, menus);
      // const menusList = [];
      // accessedRoutes.forEach(item => {})
      // this.SET_MENU(accessedRoutes)
      resolve(accessedRoutes);
    })
  }
}

export const MenuModule = getModule(Menu);
