






















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Page404 extends Vue {
  private message: string = 'The webmaster said that you can not enter this page...';
}
