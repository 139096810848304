





































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { dowmCertifid } from '@/api/credential'
import { ICertificateInfo } from '@/types';
import vueQr from 'vue-qr'

@Component({
  components: {
    vueQr
  }
})
export default class DigCredentialImage extends Vue {
  @Prop({ default: false }) visible!: boolean
  @Prop({ default: false }) certificateInfo!: ICertificateInfo

  private title: string = '查看证书'
  private src: string = ''
  private btnLoading: boolean = false

  @Emit('receiveVisible')
  private onClose() {
    return false
  }

  get dlgVisible(): boolean {
    return this.visible
  }

  //  下载
  private btnDownload(id: string) {
    this.btnLoading = true
    dowmCertifid(id).then((res: any) => {
      const content = res.data;
      const blob = new Blob([content], { type: 'application/octet-stream' }); // 构造一个blob对象来处理数据
      const fileName = this.certificateInfo.evidenceId + '.pdf'
      const link = document.createElement('a'); // 创建a标签
      link.download = fileName;
      link.style.display = 'none';
      link.href = URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click(); // 执行下载
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    }).finally(() => {
      this.btnLoading = false
    })
  }
}
